import React from "react";
import { Box, Modal, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import successImg from "../../../assets/success_icon.svg";

function Success({ open, handleClose, status, loader, message }) {
  return (
    <>
      {/* Success modal screen */}
      <Modal open={open}>
        <Box
          className="modal-box"
          sx={{ width: { xs: "320px", sm: "480px", md: "540px", lg: "540px" } }}
        >
          {loader ? (
            <div class="loader-inner">
              <div class="line-spin-fade-loader">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
            </div>
          ) : (
            <img
              src={successImg}
              alt="success-msg"
              style={{ width: "72px", height: "72px" }}
            />
          )}

          <Typography variant="h4">
            {loader
              ? "Slide Creation In Progress"
              : message
              ? ""
              : "Slides Created"}
          </Typography>
          <Typography
            variant="h5"
            sx={{ textAlign: "center", color: "#FFFFFF80" }}
          >
            {message ? (
              message
            ) : status === "success" ? (
              ""
            ) : (
              <p style={{ lineHeight: "27px" }}>
                It might take a few seconds to load.
                <br />
                Please be patient.
              </p>
            )}
          </Typography>
          {status === "success" && (
            <Box className="CloseIcon" onClick={handleClose}>
              <CloseIcon sx={{ fontSize: 15 }} />
            </Box>
          )}
        </Box>
      </Modal>
    </>
  );
}

export default Success;
