import React from "react";
import "./Home.css";
import { Box, Typography, Grid } from "@mui/material";
import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";
import MiddleContent from "./MiddleContent";
import arrow from "../../assets/arrow.png";
import Card from "../Common/Card/Card";
import reactangle from "../../assets/Rectangle.png";
import transition from "../Common/Transition/Transition";
import { styled } from '@mui/material/styles';
import { Link } from 'react-router-dom';

function Home() {
  const StyledLink = styled(Link)(({ theme }) => ({
    textDecoration: 'none', 
    color: 'inherit', 
    display: 'flex', 
    alignItems: 'center', 
  }));
  
  const [cardData] = React.useState([
    {
      id: 1,
      title: "With BoldPreso",
      content: "Say goodbye to tedious formatting and design struggles.",
      width: { xs: "100%", sm: "100%", md: "240px", lg: "270px" },
      height: { xs: "250px", sm: "260px", md: "290px", lg: "330px" },
      color: "#E86754",
      rotate: "-15",
      translate: "-280px, 5px",
      left: { xs: "250px", sm: "260px", md: "-210px", lg: "-300px" },
      top: { xs: "0", sm: "0", md: "0", lg: "0" },
    },
    {
      id: 2,
      title: "Intelligent tools",
      content:
        "Intelligent tools that craft visually stunning slides, tailored to your content.",
      width: { xs: "100%", sm: "100%", md: "240px", lg: "270px" },
      height: { xs: "250px", sm: "260px", md: "290px", lg: "330px" },
      color: "#96F197",
      rotate: "0",
      translate: "-40px ,0px",
      left: { xs: "0", sm: "0", md: "0px", lg: "-60px" },
      top: { xs: "0", sm: "0", md: "0", lg: "0" },
    },
    {
      id: 3,
      title: "With BoldPreso",
      content:
        "Dive into the world of hassle-free, impactful presentations today!",
      width: { xs: "100%", sm: "100%", md: "240px", lg: "270px" },
      height: { xs: "250px", sm: "260px", md: "290px", lg: "330px" },
      color: "linear-gradient(180deg, #D184FF 0%, #E7C0FF 100%)",
      rotate: "35",
      translate: "90px, 160px",
      left: { xs: "0", sm: "0", md: "60px", lg: "130px" },
      top: { xs: "0", sm: "0", md: "140px", lg: "140px" },
    },
  ]);

  const [show, setShow] = React.useState(false);

  React.useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 400) {
        setShow(true);
      } else {
        setShow(false);
      }
    });
  }, []);

  return (
    <Box
      className="home-container"
      sx={{ marginTop: { xs: "150px", sm: "150px", md: "12%", lg: "12%" } }}
    >
      <Grid container px={"15px"}>
        <Grid item lg={2} md={2} sm={6} xs={12}>
          <div className="home-arrow-logo">
            <Box
              component="img"
              src={arrow}
              alt={"arrow"}
              sx={{
                width: { xs: "120px", sm: "140px", md: "160px", lg: "194px" },
                rotate: { xs: "45deg", sm: "45deg", md: "none", lg: "none" },
              }}
            />
          </div>
        </Grid>
        <Grid item lg={10} md={10} sm={6} xs={12} mt={"20px"}>
          <Box
            sx={{
              marginLeft: { xs: "0", sm: "16px", md: "16px", lg: "16px" },
            }}
          >
            <Typography
              className="home-header"
              sx={{
                fontSize: { xs: "18px", sm: "18px", md: "18px", lg: "22px" },
                textAlign: {
                  xs: "center",
                  sm: "center",
                  md: "left",
                  lg: "left",
                },
              }}
            >
              Empower Your Ideas with BoldPreso - <br /> Presentation Genius at
              Your Fingertips!
            </Typography>
            <Box
              sx={{
                display: { xs: "flex", sm: "flex", md: "block", lg: "block" },
                justifyContent: "center",
              }}
            >
              {/* <Box
                className="home-header-btn"
                sx={{
                  width: { xs: "300px", sm: "330px", md: "350px", lg: "380px" },
                  borderWidth: { xs: "2px", sm: "2px", md: "2px", lg: "1px" },
                }}
              >
                <Box className="home-header-btn-text" sx={{ width: "75%" }}>
                  <p>create Presentation now</p>{" "}
                </Box>
                <Box
                  className="home-header-btn-icon"
                  sx={{
                    borderWidth: { xs: "2px", sm: "2px", md: "2px", lg: "1px" },
                  }}
                >
                  <ArrowOutwardIcon />
                </Box>
              </Box> */}
              <StyledLink
                to="/signup"
                className="home-header-btn"
                sx={{
                  width: { xs: "300px", sm: "330px", md: "350px", lg: "380px" },
                  borderWidth: { xs: "2px", sm: "2px", md: "2px", lg: "1px" },
                }}
              >
                <Box className="home-header-btn-text" sx={{ width: "75%" }}>
                  <p>Create Presentation Now</p>
                </Box>
                <Box
                  className="home-header-btn-icon"
                  sx={{
                    borderWidth: { xs: "2px", sm: "2px", md: "2px", lg: "1px" },
                  }}
                >
                  <ArrowOutwardIcon />
                </Box>
              </StyledLink>
            </Box>
          </Box>
        </Grid>
      </Grid>
      <section className="home-section2">
        <Typography
          className="home-banner-text"
          sx={{
            fontSize: {
              xs: "63px",
              sm: "140px",
              md: "195px",
              lg: "240px",
              xl: "280px",
            },
          }}
        >
          IN SECOND
        </Typography>
      </section>
      <section className="home-section3">
        <Grid container>
          <Grid
            item
            lg={4}
            md={4}
            sm={12}
            xs={12}
            sx={{ display: "flex", alignItems: "center" }}
          >
            <Box
              className="section3-content-box"
              sx={{
                paddingRight: {
                  xs: "40px",
                  sm: "40px",
                  md: "20px",
                  lg: "15px",
                  xl: "15px",
                },
              }}
            >
              <Typography
                className="section3-title"
                sx={{
                  fontSize: { xs: "18px", sm: "20px", md: "28px", lg: "30px" },
                }}
              >
                Welcome to BoldPreso
              </Typography>
              <Typography
                className="section3-sub-text"
                sx={{
                  fontSize: { xs: "14px", sm: "14px", md: "16px", lg: "16px" },
                  color: "#c4c4c4",
                }}
              >
                Welcome to BoldPreso, where your ideas transform into
                captivating presentations in seconds! Our AI-driven platform
                simplifies the art of presentation creation, making it quick,
                effortless, and fun.
              </Typography>
              <Box className="section3-btn-box" mt={2}>
                <StyledLink to={"/signup"}>
                  <Box className="section3-header-btn-text">
                    <p>Create</p>{" "}
                  </Box>
                  <div className="section3-header-btn-icon">
                    <ArrowOutwardIcon />
                  </div>
                </StyledLink>
              </Box>
            </Box>
          </Grid>
          <Grid item lg={8} md={8} sm={12} xs={12} className="d-flex">
            <Box
              sx={{
                minHeight: "600px",
                position: {
                  xs: "none",
                  sm: "none",
                  md: "relative",
                  lg: "relative",
                },
                display: { xs: "flex", sm: "flex", md: "block", lg: "block" },
                flexDirection: "column",
                gap: "20px",
                padding: "30px",
              }}
            >
              {cardData.map((item) => {
                return <Card data={item} key={item.id} show={show} />;
              })}
              <Box
                className={show ? "section3-circle" : ""}
                sx={{
                  display: { xs: "none", sm: "none", md: "block", lg: "block" },
                  left: { xs: "0", sm: "0", md: "-140px", lg: "-140px" },
                  top: { xs: "0", sm: "0", md: "200px", lg: "200px" },
                }}
              ></Box>
            </Box>
          </Grid>
        </Grid>
      </section>
      <section className="home-section4">
        <MiddleContent />
      </section>
      <section className="home-section5">
        <Grid container>
          <Grid item lg={8} md={8} sm={12} xs={12}>
            <Typography
              className="section5-title"
              sx={{
                fontSize: { xs: "50px", sm: "50px", md: "70px", lg: "70px" },
              }}
            >
              EFFORTLESS PRESENTATION CREATON
            </Typography>
          </Grid>
          <Grid item lg={4} md={4} sm={12} xs={12} className="d-flex">
            <Typography
              className="section5-text"
              sx={{
                fontSize: { xs: "16px", sm: "16px", md: "18px", lg: "18px" },
                textAlign: { xs: "left", sm: "left", md: "right", lg: "right" },
              }}
            >
              With BoldPreso, you get access to a suite of inteligent tool that
              craft visually stunning slides tailored to your content
            </Typography>
          </Grid>
        </Grid>
      </section>
      <section className="home-section6">
        <Box
          className="section6-content-box"
          sx={{ background: `url(${reactangle})` }}
        >
          <Grid container>
            <Grid item lg={6} md={5} sm={12} xs={12}>
              <Typography className="section6-content-text">
                AI-powered prompts: Simply enter your topic or theme, and
                BoldPreso will generate compelling outlines, visuals, and even
                scripts, saving you hours of preparation time.
              </Typography>
            </Grid>
            <Grid item lg={6} md={7} sm={12} xs={12}>
              <Typography
                className="section6-content-text"
                sx={{
                  textAlign: { md: "right !important", lg: "right !important" },
                }}
              >
                With BoldPreso, you'll go from zero to presentation hero in no
                time. Our intuitive interface and AI-powered features make it
                easy to create slides that captivate, engage, and inspire.
                Whether you're presenting to clients, colleagues, or a global
                audience, BoldPreso ensures your message stands out.
              </Typography>
            </Grid>
          </Grid>
          <Box sx={{ display: "flex" }}>
            <div
              className="section6-content-buttons"
              style={{ borderLeft: "none" }}
            >
              QUICK
            </div>
            <div className="section6-content-buttons">EFFORTLESS</div>
            <div
              className="section6-content-buttons"
              style={{ borderRight: "none" }}
            >
              FUN
            </div>
          </Box>
        </Box>
        <Box mt={2} sx={{maxWidth:'800px',margin:'auto'}}>
          <Typography
            className="section6-content-center-text"
            sx={{
              fontSize: { xs: "16px", sm: "16px", md: "18px", lg: "20px" },
            }}
          >
            Take the first step towards presentation greatness. Sign up for
            BoldPreso today and experience the difference AI-powered slide
            creation can make. Your audience won't know what hit them – in the
            best way possible.
          </Typography>
        </Box>
        <Box className="d-flex" sx={{ height: "150px", mb: 4 }}>
          <StyledLink
            to={"/signup"}
            className="section6-create-btn"
            sx={{
              width: { xs: "300px", sm: "330px", md: "350px", lg: "380px" },
            }}
          >
            <Box
              className="section6-create-btn-text"
              sx={{
                width: "75%",
                borderWidth: { xs: "2px", sm: "2px", md: "2px", lg: "1px" },
              }}
            >
              <p>create Presentation now</p>
            </Box>
            <Box
              className="section6-create-btn-icon"
              sx={{
                borderWidth: { xs: "2px", sm: "2px", md: "2px", lg: "1px" },
              }}
            >
              <ArrowOutwardIcon />
            </Box>
          </StyledLink>
        </Box>
      </section>
    </Box>
  );
}

export default transition(Home);
