import * as React from "react";
import {
  Box,
  Button,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import WorkspacesIcon from "@mui/icons-material/Workspaces";
import HomeIcon from "@mui/icons-material/Home";
// import ContactMailIcon from "@mui/icons-material/ContactMail";
import CardGiftcardIcon from "@mui/icons-material/CardGiftcard";
import MenuIcon from "@mui/icons-material/Menu";
import { NavLink, Link } from "react-router-dom";
import boldpreso_logo from "../../assets/boldpreso_logo.png";

function DrawerComp() {
  const [state, setState] = React.useState({
    left: false,
  });

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setState({ ...state, left: open });
  };

  return (
    <div>
      <Button onClick={toggleDrawer(true)} className="drawer-menu-icon">
        <MenuIcon />
      </Button>
      <Drawer
        anchor="left"
        open={state.left}
        onClose={toggleDrawer(false)}
        className="drawer-container"
      >
        <Box
          sx={{ width: 250 }}
          role="presentation"
          onClick={toggleDrawer(false)}
          onKeyDown={toggleDrawer(false)}
        >
          <Box className="d-flex" my={3}>
            <img src={boldpreso_logo} alt="logo" width={160} height={48} />
          </Box>
          <List>
            <NavLink
              to={"/"}
              className={({ isActive }) =>
                isActive ? "sidemenu-active" : "sidemenu-inactive"
              }
            >
              <ListItem disablePadding>
                <ListItemButton sx={{ padding: "15px 20px" }}>
                  <ListItemIcon className="sidemenu-icon">
                    <HomeIcon />
                  </ListItemIcon>
                  <ListItemText primary="Home" />
                </ListItemButton>
              </ListItem>
            </NavLink>
            <NavLink
              to={"/feature"}
              className={({ isActive }) =>
                isActive ? "sidemenu-active" : "sidemenu-inactive"
              }
            >
              <ListItem disablePadding>
                <ListItemButton sx={{ padding: "15px 20px" }}>
                  <ListItemIcon className="sidemenu-icon">
                    <WorkspacesIcon />
                  </ListItemIcon>
                  <ListItemText primary="Features" />
                </ListItemButton>
              </ListItem>
            </NavLink>
            <NavLink
              to={"/price"}
              className={({ isActive }) =>
                isActive ? "sidemenu-active" : "sidemenu-inactive"
              }
            >
              <ListItem disablePadding>
                <ListItemButton sx={{ padding: "15px 20px" }}>
                  <ListItemIcon className="sidemenu-icon">
                    <CardGiftcardIcon />
                  </ListItemIcon>

                  <ListItemText primary="Pricing" />
                </ListItemButton>
              </ListItem>
            </NavLink>
            {/* <NavLink
              to={"/about"}
              className={({ isActive }) =>
                isActive ? "sidemenu-active" : "sidemenu-inactive"
              }
            >
              <ListItem disablePadding>
                <ListItemButton sx={{ padding: "15px 20px" }}>
                  <ListItemIcon className="sidemenu-icon">
                    <ContactMailIcon />
                  </ListItemIcon>
                  <ListItemText primary="About" />
                </ListItemButton>
              </ListItem>
            </NavLink> */}
          </List>
          <List>
            <Link to="/login">
              <Button className="drawer-login-btn">Login</Button>
            </Link>
          </List>
          <List>
            <Link to="/signup">
              <Button className="drawer-signup-btn">Get Started</Button>
            </Link>
          </List>
        </Box>
      </Drawer>
    </div>
  );
}

export default DrawerComp;
