import { combineReducers } from "@reduxjs/toolkit";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import authReducer from "../../components/Login/reducer";
import presentationReducer from "../../components/Presentation/reducer";
import chatReducer from "../../components/AI/reducer";
import paymentReducer from "../../components/Subscription/reducer";
import myFileReducer from "../../components/MyFile/reducer";

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["auth", "chat"],
};

const appReducer = combineReducers({
  auth: authReducer,
  presentation: presentationReducer,
  chat: chatReducer,
  payment: paymentReducer,
  myFile:myFileReducer
});

const rootReducer = (state, action) => {
  if (action.type === "auth/logout") {
    storage.removeItem("persist:root");
    return appReducer(undefined, action);
  }
  return appReducer(state, action);
};

export default persistReducer(persistConfig, rootReducer);
