import React, { useEffect, useRef, useState } from "react";
import { Container, TextField, Button, Grid } from "@mui/material";
import { Box, InputAdornment, Typography, Avatar } from "@mui/material";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import "./Chat.css";
import {
  makePPTAgent,
  setMessageData,
  selectMessageData,
  selectWebScoketId,
  setResponseMsg,
  setWebScoketId,
} from "./reducer";
import { useDispatch, useSelector } from "react-redux";
import Markdown from "react-markdown";

function Chat() {
  const [input, setInput] = useState("");
  const lastMessageRef = useRef(null);
  const dispatch = useDispatch();
  const messageData = useSelector(selectMessageData);
  const socketId = useSelector(selectWebScoketId);

  const [msgLoading, setMsgLoading] = useState(false);
  const userName = useSelector((state) => state.auth.full_name);
  const [name, setName] = useState("");
  useEffect(() => {
    scrollToBottom();
  }, [messageData]);

  const scrollToBottom = () => {
    lastMessageRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const handleMessage = (e) => {
    setInput(e.target.value);
  };

  const handleMessageSend = () => {
    if (input.trim() !== "") {
      dispatch(setMessageData(input));
      const data = {
        prompt: input,
        chat_history: messageData,
      };
      dispatch(makePPTAgent(data));
      setMsgLoading(true);
    }
    setInput("");
  };

  useEffect(() => {
    if (socketId) {
      console.log(socketId);
      const socketUrl = `wss://backend-dot-boldpreso-prd.uc.r.appspot.com/presentation/ws?websocket_id=${socketId}`;
      const socket = new WebSocket(socketUrl);
      socket.onopen = () => {
        console.log("WebSocket connected");
      };

      socket.onmessage = (event) => {
        setMsgLoading(true);
        dispatch(setResponseMsg(event.data));
      };

      socket.onerror = (error) => {
        console.error("WebSocket error: ", error);
      };
      socket.onclose = () => {
        console.log("WebSocket closed");
        dispatch(setWebScoketId());
        setMsgLoading(false);
      };
      return () => {
        socket.close();
      };
    }
  }, [socketId, dispatch]);

  useEffect(() => {
    const name = userName.split(" ");
    if (name.length >= 2) {
      setName(name[0].charAt(0).toUpperCase() + name[1].charAt(0).toUpperCase())
      //return name[0].charAt(0).toUpperCase() + name[1].charAt(0).toUpperCase();
    } else {
      //return name[0].charAt(0).toUpperCase();
      setName(name[0].charAt(0).toUpperCase())
    }
  }, [userName])

  return (
    <Container className="chat-container">
      <Box className="AIchat" id="box">
        {messageData.length === 0 && (
          <Box className="helpText">Can you make me a presentation on the universe?</Box>
        )}
        {messageData.map((message, i) => (
          <Box
            key={i}
            ref={i === messageData.length - 1 ? lastMessageRef : null}
          >
            <Grid container justifyContent="flex-start">
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Box sx={{ color: "#fff" }}>
                  <Box className="user-details">
                    <Avatar className="avatar" sx={{ bgcolor: "#fff" }}>
                      {name}
                    </Avatar>
                    <Typography variant="body1" className="user-name">
                      {userName.charAt(0).toUpperCase() + userName.slice(1)}
                    </Typography>
                  </Box>
                  <Typography variant="body1" className="message-content">
                    {message[0]}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} mt={5} mb={5}>
                <Box sx={{ color: "#fff" }}>
                  <Box className="user-details">
                    <Avatar className="avatar" sx={{ bgcolor: "#D286FF" }}>
                      BP
                    </Avatar>
                    <Typography variant="body1" className="user-name">
                      BoldPreso
                    </Typography>
                  </Box>
                  <div className="message-content">
                    {!message[1] ? (
                      <div className="chatloader"></div>
                    ) : (
                      <Markdown className="markdown">
                        {message[1]}
                      </Markdown>
                    )}
                  </div>
                </Box>
              </Grid>
            </Grid>
          </Box>
        ))}
      </Box>
      <Box>
        <TextField
          placeholder="Enter text here"
          variant="filled"
          fullWidth
          className="textfield"
          InputLabelProps={{
            shrink: false,
          }}
          InputProps={{
            disableUnderline: true,
            autoComplete: "off",
            style: { height: "100px" },
            onKeyDown: (e) => {
              if (!msgLoading && e.key === "Enter") {
                handleMessageSend();
              }
            },
            endAdornment: (
              <InputAdornment position="end">
                <Button
                  variant="contained"
                  onClick={handleMessageSend}
                  disabled={msgLoading ? true : false}
                  className="submit-btn"
                >
                  {msgLoading ? (
                    <div className="chatloader2"></div>
                  ) : (
                    <ArrowUpwardIcon />
                  )}
                </Button>
              </InputAdornment>
            ),
          }}
          value={input}
          onChange={handleMessage}
          style={{ marginTop: "20px" }}
        />
      </Box>
    </Container>
  );
}

export default Chat;

