import React, { useState, useEffect } from "react";
import "./login.css";
import { VisibilityOutlined, VisibilityOffOutlined } from "@mui/icons-material";
import {
  Box,
  Button,
  Typography,
  TextField,
  IconButton,
  Checkbox,
  InputAdornment,
} from "@mui/material";
// import AppleIcon from "@mui/icons-material/Apple";
import google_logo from "../../assets/google_logo.svg";
import boldpreso_logo from "../../assets/boldpreso_logo.png";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  createUser,
  getUser,
  selectIsLoggedIn,
  setIsLoggedIn,
  setRefreshToken,
  setToken,
  getTemplates
} from "./reducer";
import { auth } from "../../services/Firebase/firebase.config";
import {
  GoogleAuthProvider,
  signInWithPopup,
  // OAuthProvider,
  signInWithEmailAndPassword
} from "firebase/auth";

import Loader from "../Common/Loader/Loader";
import { toast } from "react-toastify";
import { CredentialsValidation } from "../Common/Validation/Validation";

function Login() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isLoggedIn = useSelector(selectIsLoggedIn);

  const [showPwd, setShowPwd] = useState(false);
  const [terms, setTerms] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loader, setLoader] = useState(false);
  const [emailErrors, setEmailError] = useState("");
  const [passwordErrors, setPasswordError] = useState("");
  const [termsErrors, setTermsError] = useState("");

  const handleTogglePasswordVisibility = () => {
    setShowPwd((prevPwd) => !prevPwd);
  };

  const handleCheckboxChange = (event) => {
    setTerms(event.target.checked);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const validate = CredentialsValidation(
      email,
      password,
      terms,
      setEmailError,
      setPasswordError,
      setTermsError
    );
    if (validate) {
      try {
        setLoader(true);
        const userCredential = await signInWithEmailAndPassword(
          auth,
          email,
          password
        );
        const user = userCredential.user;
        console.log(user);
        if (!user.emailVerified) {
          setLoader(false);
          toast.error("Please verify your email.");
          return;
        }
        const { accessToken, refreshToken } = user.stsTokenManager;
        dispatch(setRefreshToken(refreshToken));
        dispatch(setToken(accessToken));
        dispatch(setIsLoggedIn(true));
        setLoader(false);
        dispatch(getUser());
        await dispatch(getTemplates());
        toast.success("Logged in successfully");
      } catch (error) {
        console.log("error-->", error);
        setLoader(false);
        toast.error("Please enter a valid Email ID or Password");
      }
    }
  };

  const handleGoogle = async () => {
    const provider = new GoogleAuthProvider();
    try {
      const userCredential = await signInWithPopup(auth, provider);
      const newUser = userCredential._tokenResponse?.isNewUser;
      const refreshToken = userCredential._tokenResponse?.refreshToken;
      const user = userCredential.user;
      const token = await user.getIdToken();

      if (token) {
        dispatch(setToken(token));
        dispatch(setRefreshToken(refreshToken));
        if (newUser) {
          dispatch(createUser());
        }
        dispatch(getUser());
        await dispatch(getTemplates());
        dispatch(setIsLoggedIn(true));
      }
    } catch (error) {
      console.error(error);
      toast.error(error.code);
    }
  };

  // const handleApple = async (e) => {
  //   const provider = new OAuthProvider("apple.com");
  //   try {
  //     const userCredential = await signInWithPopup(auth, provider);
  //     const user = userCredential.user;
  //     const token = await user.getIdToken();
  //     console.log(token);
  //     dispatch(setIsLoggedIn(true));
  //   } catch (error) {
  //     console.error(error);
  //     alert(error.message);
  //   }
  // };

  useEffect(() => {
    if (isLoggedIn) {
      navigate("/presentation");
    }
  }, [isLoggedIn, navigate]);

  return (
    <>
      {loader ? (
        <Loader />
      ) : (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            background: "#131619",
            minHeight: "100vh",
          }}
        >
          <Box className="form-container">
            <NavLink to="/">
              {" "}
              <img
                src={boldpreso_logo}
                alt="logo"
                width={175}
                height={55}
              />{" "}
            </NavLink>
            <Button
              className="google-btn"
              onClick={handleGoogle}
              sx={{
                color: "#989a9c !important",
                width: { xs: "300px", sm: "430px", md: "430px", lg: "430px" },
              }}
            >
              <img src={google_logo} alt="Google_Logo" width={45} />
              Continue with Google
            </Button>
            {/* <Button
          className="apple-btn"
          onClick={handleApple}
          sx={{
            backgroundColor: "#D286FF !important",
            width: { xs: "300px", sm: "430px", md: "430px", lg: "430px" },
          }}
        >
          <AppleIcon sx={{ fontSize: 42 }} />
          Continue with Apple
        </Button> */}
            <p style={{ color: "#818181", margin: "15px" }}>Or</p>
            <form className="form-container">
              <Box>
                <TextField
                  placeholder="Email ID"
                  className="textfield"
                  sx={{
                    width: {
                      xs: "300px",
                      sm: "430px",
                      md: "430px",
                      lg: "430px",
                    },
                    mb: "4px",
                  }}
                  InputLabelProps={{
                    shrink: false,
                  }}
                  InputProps={{
                    autoComplete: "off",
                  }}
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <Typography
                  variant="body1"
                  pl={0.5}
                  my={0.5}
                  className="errorField"
                >
                  {emailErrors}
                </Typography>
              </Box>
              <Box>
                <TextField
                  placeholder="Password"
                  className="textfield"
                  type={showPwd ? "text" : "password"}
                  sx={{
                    width: {
                      xs: "300px",
                      sm: "430px",
                      md: "430px",
                      lg: "430px",
                    },
                  }}
                  InputLabelProps={{
                    shrink: false,
                  }}
                  InputProps={{
                    autoComplete: "off",
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={handleTogglePasswordVisibility}
                          edge="end"
                          sx={{ color: "#989A9C", marginRight: "2px" }}
                        >
                          {showPwd ? (
                            <VisibilityOffOutlined />
                          ) : (
                            <VisibilityOutlined />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                <Typography
                  variant="body1"
                  pl={0.5}
                  my={0.5}
                  className="errorField"
                  sx={{
                    width: {
                      xs: "300px",
                      sm: "430px",
                      md: "430px",
                      lg: "430px",
                    },
                  }}
                >
                  {passwordErrors}
                </Typography>
              </Box>
              <Box>
                <div className="terms">
                  <Checkbox
                    checked={terms}
                    onChange={handleCheckboxChange}
                    color="primary"
                  />
                  <Typography
                    variant="body1"
                    sx={{
                      color: "#818181",
                      width: {
                        xs: "260px",
                        sm: "400px",
                        md: "400px",
                        lg: "400px",
                      },
                    }}
                  >
                    I agree to the Terms of services and Privacy policy
                  </Typography>
                </div>
                <Typography
                  variant="body1"
                  pl={1.5}
                  sx={{ textAlign: "start" }}
                  className="errorField"
                >
                  {termsErrors}
                </Typography>
              </Box>

              <Button
                className="signup-btn"
                sx={{
                  background: "#D286FF !important",
                  width: { xs: "300px", sm: "430px", md: "430px", lg: "430px" },
                }}
                onClick={handleSubmit}
              >
                Continue
              </Button>
              <div className="login-link">
                <p>Don't have an account?</p>
                <span>
                  <Link to="/signup">Sign Up</Link>
                </span>
              </div>
            </form>
          </Box>
        </Box>
      )}
    </>
  );
}

export default Login;
