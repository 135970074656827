import React, { useEffect, useState } from "react";
import "./login.css";
import { VisibilityOutlined, VisibilityOffOutlined } from "@mui/icons-material";
import {
  Typography,
  TextField,
  IconButton,
  Checkbox,
  InputAdornment,
  Box,
  Button
} from "@mui/material";
import google_logo from "../../assets/google_logo.svg";
// import AppleIcon from "@mui/icons-material/Apple";

import boldpreso_logo from "../../assets/boldpreso_logo.png";
import { Link, NavLink, useNavigate, useLocation } from "react-router-dom";
import {
  createUserWithEmailAndPassword,
  GoogleAuthProvider,
  signInWithPopup,
  sendEmailVerification
} from "firebase/auth";
import { useDispatch, useSelector } from "react-redux";
import {
  selectIsLoggedIn,
  setIsLoggedIn,
  setToken,
  createUser,
  getUser,
  getTemplates
} from "./reducer";
import { auth } from "../../services/Firebase/firebase.config";
import { toast } from "react-toastify";
import Loader from "../Common/Loader/Loader";
import { CredentialsValidation } from "../Common/Validation/Validation";

function Signup() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const isLoggedIn = useSelector(selectIsLoggedIn);
  const [showPwd, setShowPwd] = useState(false);
  const [agreeToTerms, setAgreeToTerms] = useState(false);
  const [email, setEmail] = useState(location.state?.email || '');
  const [password, setPassword] = useState("");
  const [loader, setLoader] = useState(false);
  const [emailErrors, setEmailError] = useState("");
  const [passwordErrors, setPasswordError] = useState("");
  const [termsErrors, setTermsError] = useState("");

  const handleTogglePasswordVisibility = () => {
    setShowPwd((prevPwd) => !prevPwd);
  };

  const handleCheckboxChange = (event) => {
    setAgreeToTerms(event.target.checked);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const validate = CredentialsValidation(
      email,
      password,
      agreeToTerms,
      setEmailError,
      setPasswordError,
      setTermsError
    );
    if (validate) {
      try {
        setLoader(true);
        const userCredential = await createUserWithEmailAndPassword(
          auth,
          email,
          password
        );
        const user = userCredential.user;
        await sendEmailVerification(user);
        console.log(userCredential);
        const token = await user.getIdToken();
        console.log(token);
        if (token) {
          setLoader(false);
          navigate("/login");
          toast.success(
            "Signup Successful. Verification email sent. Please check your email."
          );
        }
      } catch (error) {
        console.error(error.code);
        console.error(error.message);
        setLoader(false);
        if (error.code === "auth/email-already-in-use") {
          toast.error("The email address is already in use");
        } else if (error.code === "auth/invalid-email") {
          toast.error("The email address is not valid.");
        } else if (error.code === "auth/operation-not-allowed") {
          toast.error("Operation not allowed.");
        } else if (error.code === "auth/weak-password") {
          toast.error("The password is too weak.");
        } else {
          toast.error(error.code);
        }
      }
    }
  };
  const handleGoogle = async () => {
    const provider = new GoogleAuthProvider();
    try {
      const userCredential = await signInWithPopup(auth, provider);
      const newUser = userCredential._tokenResponse?.isNewUser;
      const user = userCredential.user;
      const token = await user.getIdToken();

      if (token) {
        dispatch(setToken(token));
        if (newUser) {
          dispatch(createUser());
        }
        dispatch(getUser());
        await dispatch(getTemplates());
        dispatch(setIsLoggedIn(true));
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  useEffect(() => {
    if (isLoggedIn) {
      navigate("/presentation");
    }
  }, [isLoggedIn, navigate]);

  return (
    <>
      {loader ? (
        <Loader />
      ) : (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            background: "#131619",
            minHeight: "100vh",
          }}
        >
          <Box className="form-container">
            <NavLink to="/">
              {" "}
              <img
                src={boldpreso_logo}
                alt="logo"
                width={175}
                height={55}
              />{" "}
            </NavLink>
            <Button
              className="google-btn"
              onClick={handleGoogle}
              sx={{
                width: { xs: "300px", sm: "430px", md: "430px", lg: "430px" },
                color: "#989a9c !important",
              }}
            >
              <img src={google_logo} alt="Google_Logo" width={45} />
              Continue with Google
            </Button>

            {/* <Button
          className="apple-btn"
          sx={{ width: { xs: "300px", sm: "430px", md: "430px", lg: "430px" } }}
        >
          <AppleIcon sx={{ fontSize: 42 }} />
          Continue with Apple
        </Button> */}
            <p style={{ color: "#818181", margin: "15px" }}>Or</p>
            <form className="form-container">
              <Box>
                <TextField
                  placeholder="Email ID"
                  className="textfield"
                  sx={{
                    width: {
                      xs: "300px",
                      sm: "430px",
                      md: "430px",
                      lg: "430px",
                    },
                    mb: "4px",
                  }}
                  InputLabelProps={{
                    shrink: false,
                  }}
                  InputProps={{
                    autoComplete: "off",
                  }}
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <Typography
                  variant="body1"
                  pl={0.5}
                  my={0.5}
                  className="errorField"
                >
                  {emailErrors}
                </Typography>
              </Box>
              <Box>
                <TextField
                  placeholder="Password"
                  className="textfield"
                  type={showPwd ? "text" : "password"}
                  sx={{
                    width: {
                      xs: "300px",
                      sm: "430px",
                      md: "430px",
                      lg: "430px",
                    },
                  }}
                  InputLabelProps={{
                    shrink: false,
                  }}
                  InputProps={{
                    autoComplete: "off",
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={handleTogglePasswordVisibility}
                          edge="end"
                          sx={{ color: "#989A9C", marginRight: "2px" }}
                        >
                          {showPwd ? (
                            <VisibilityOffOutlined />
                          ) : (
                            <VisibilityOutlined />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                <Typography
                  variant="body1"
                  pl={0.5}
                  my={0.5}
                  className="errorField"
                  sx={{
                    width: {
                      xs: "300px",
                      sm: "430px",
                      md: "430px",
                      lg: "430px",
                    },
                  }}
                >
                  {passwordErrors}
                </Typography>
              </Box>
              <Box>
                <div className="terms">
                  <Checkbox
                    checked={agreeToTerms}
                    onChange={handleCheckboxChange}
                    color="primary"
                  />
                  <Typography
                    variant="body1"
                    sx={{
                      color: "#818181",
                      width: {
                        xs: "260px",
                        sm: "400px",
                        md: "400px",
                        lg: "400px",
                      },
                    }}
                  >
                    I agree to the Terms of services and Privacy policy
                  </Typography>
                </div>
                <Typography
                  variant="body1"
                  pl={1.5}
                  sx={{ textAlign: "start !imopotant" }}
                  className="errorField"
                >
                  {termsErrors}
                </Typography>
              </Box>
              <Button
                className="signup-btn"
                onClick={handleSubmit}
                sx={{
                  width: { xs: "300px", sm: "430px", md: "430px", lg: "430px" },
                  background: "#D286FF !important",
                }}
              >
                Sign Up
              </Button>
              <div className="login-link">
                <p>Already have an account?</p>
                <span>
                  <Link to="/login">Sign In</Link>
                </span>
              </div>
            </form>
          </Box>
        </Box>
      )}
    </>
  );
}

export default Signup;
