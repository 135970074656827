import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import * as API from "../../api/index";

const initialState = {
  messageData: [],
  webSocketId: "",
  ui: {
    chat: {
      loading: false,
    },
  },
};

const makePPTAgent = createAsyncThunk("make/PPTAgent", async (params) => {
  const res = await API.makePPTAgent(params);
  return res.data;
});

export const chatSlice = createSlice({
  name: "chat",
  initialState,
  reducers: {
    setMessageData: (state, action) => {
      return {
        ...state,
        messageData: [...state.messageData, [action.payload, ""]],
      };
    },
    setResponseMsg: (state, action) => {
      if (state.messageData.length > 0) {
        const lastIndex = state.messageData.length - 1;
        const lastMessage = state.messageData[lastIndex];
        const replaceMessage = action.payload.replace(
          /^\[START\]|\[END\]$/g,
          ""
        );
        lastMessage[lastMessage.length - 1] += replaceMessage;
      }
    },
    setWebScoketId: (state) => {
      state.webSocketId = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(makePPTAgent.pending, (state, action) => {
        state.ui.chat.loading = true;
      })
      .addCase(makePPTAgent.fulfilled, (state, action) => {
        state.ui.chat.loading = false;
        state.webSocketId = action.payload.websocket_id;
      })
      .addCase(makePPTAgent.rejected, (state, action) => {
        state.ui.chat.loading = false;
      });
  },
});

export const {
  setChatData,
  setMessageData,
  setResponseMsg,
  setUserMsg,
  setWebScoketId,
} = chatSlice.actions;

export { makePPTAgent };

export const selectMessageData = (state) => state.chat.messageData;
export const selectWebScoketId = (state) => state.chat.webSocketId;

export default chatSlice.reducer;
