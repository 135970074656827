export const CredentialsValidation = (
  email,
  password,
  terms,
  setEmailError,
  setPasswordError,
  setTermsError
) => {
  const isValidEmailDomain = (email) => {
    const regex = /@(gmail\.com|mailinator\.com)$/;
    return regex.test(email);
  };

  const isPasswordValid = (password) => {
    const passwordRegex =
      /^(?=.*[A-Z])(?=.*[!@#$%^&*(),.?":{}|<>])(?=.*\d)(?=.*[a-z]).{6,}$/;
    return passwordRegex.test(password);
  };

  if (!email) {
    setPasswordError("");
    setTermsError("");
    setEmailError("Please enter the email");
  } else if (!isValidEmailDomain(email)) {
    setPasswordError("");
    setTermsError("");
    setEmailError("Please enter the correct email");
  } else if (!password) {
    setEmailError("");
    setTermsError("");
    setPasswordError("Please enter the password");
  } else if (!isPasswordValid(password)) {
    setEmailError("");
    setTermsError("");
    setPasswordError(
      "Password must contain 6 characters with one uppercase letter, one number and one special character."
    );
  } else if (!terms) {
    setEmailError("");
    setPasswordError("");
    setTermsError("Accept Terms & Conditions");
  } else {
    setEmailError("");
    setPasswordError("");
    setTermsError("");
    return true;
  }
};

export const PresentationFormValidation = (
  topic,
  instructions,
  number_of_pages,
  negative_prompt,
  templateName,
  setTopicError,
  setInstructionsError,
  setNumberOfPagesError,
  // setPromptError,
  setTemplateNameError
) => {
  if (!topic) {
    setTemplateNameError("");
    setInstructionsError("");
    setNumberOfPagesError("");
    setTopicError("Please enter the topic name");
  } else if (!number_of_pages) {
    setTopicError("");
    setTemplateNameError("");
    setInstructionsError("");
    setNumberOfPagesError("Please enter the number of pages");
  } else if (number_of_pages <= 0) {
    setTopicError("");
    setTemplateNameError("");
    setInstructionsError("");
    setNumberOfPagesError("Please enter a valid number of pages");
  } else if (!instructions) {
    setTopicError("");
    setTemplateNameError("");
    setNumberOfPagesError("");
    setInstructionsError("Please enter the instruction");
  } else if (!templateName) {
    setInstructionsError("");
    setTopicError("");
    setNumberOfPagesError("");
    setTemplateNameError("Please select the template name");
  }
  // else if (!negative_prompt) {
  //   setTemplateNameError("")
  //   setPromptError("Enter Your Prompt Address")
  // }
  else {
    setInstructionsError("");
    setTopicError("");
    setNumberOfPagesError("");
    setTemplateNameError("");
    return true;
  }
};
