import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import * as API from "../../api/index";

const initialState = {
  subscriptions: [],
  ui: {
    subscriptions: {
      loading: false,
    },
    checkout: {
      loading: false,
    },
    cancel: {
      status: "",
      loading: false,
    },
  },
};

const getSubscriptionData = createAsyncThunk(
  "get/subscriptionData",
  async () => {
    const res = await API.getSubscriptionData();
    return res.data;
  }
);
const createCheckout = createAsyncThunk("create/checkout", async () => {
  const res = await API.createCheckout();
  return res.data;
});
const cancelSubscription = createAsyncThunk(
  "cancel/subscription",
  async (params) => {
    const res = await API.cancelSubscription(params);
    return res.data;
  }
);

export const paymentSlice = createSlice({
  name: "payment",
  initialState,
  reducers: {
    setCheckotUrl: (state) => {
      state.checkoutUrl = "";
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(getSubscriptionData.pending, (state, action) => {
        state.ui.subscriptions.loading = true;
      })
      .addCase(getSubscriptionData.fulfilled, (state, action) => {
        state.ui.subscriptions.loading = false;
        state.subscriptions = action.payload.subscriptions;
      })
      .addCase(getSubscriptionData.rejected, (state, action) => {
        state.ui.subscriptions.loading = false;
      });
    builder
      .addCase(createCheckout.pending, (state, action) => {
        state.ui.checkout.loading = true;
      })
      .addCase(createCheckout.fulfilled, (state, action) => {
        state.ui.checkout.loading = false;
        if (action.payload.checkout_url) {
          window.location.href = action.payload.checkout_url;
        }
      })
      .addCase(createCheckout.rejected, (state, action) => {
        state.ui.checkout.loading = false;
      });
    builder
      .addCase(cancelSubscription.pending, (state, action) => {
        state.ui.cancel.loading = true;
      })
      .addCase(cancelSubscription.fulfilled, (state, action) => {
        state.ui.cancel.loading = false;
        state.ui.cancel.status = action.payload.status;
      })
      .addCase(cancelSubscription.rejected, (state, action) => {
        state.ui.cancel.loading = false;
      });
  },
});

export const { setCheckotUrl } = paymentSlice.actions;

export { getSubscriptionData, createCheckout, cancelSubscription };

export const selectSubscriptions = (state) => state.payment.subscriptions;
export const selectSubLoader = (state) =>
  state.payment.ui.subscriptions.loading;
export const selectPaymentLoader = (state) => state.payment.ui.checkout.loading;
export const selectCancelLoader = (state) => state.payment.ui.cancel.loading;
export const selectCancelStatus = (state) => state.payment.ui.cancel.status;

export default paymentSlice.reducer;
