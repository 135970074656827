import React, { useState } from "react";
import { Grid, Typography, InputAdornment, Box } from "@mui/material";
import { TextField, Button } from "@mui/material";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import visaCard from "../../assets/Visa.svg";
import masterCard from "../../assets/masterCard.svg";
import "./Pricing.css";

const PaymentForm = () => {
    const [formData, setFormData] = useState({
        email: "",
        cardNumber: "",
        expiryDate: "",
        cvc: "",
        holderName: "",
        country: "",
    });

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        console.log(formData);
    };

    return (
        <Grid container mt={4} className="paymentform-container">
            <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}

            >
                <Typography variant="h5" className="paymentform-title" mb={4}>
                    Pay with card
                </Typography>
                <TextField
                    type="email"
                    name="email"
                    className="paymentform-textfield"
                    placeholder="Email"
                    fullWidth
                    value={formData.email}
                    onChange={handleChange}
                    InputLabelProps={{
                        shrink: false,
                    }}
                    InputProps={{
                        disableUnderline: true,
                        autoComplete: "off",
                    }}
                />
                <Typography variant="body1" className="paymentform-lebel" mb={2}>
                    Card information
                </Typography>
                <TextField
                    placeholder="1234 1234 1234 1234"
                    type="text"
                    className="paymentform-textfield"
                    name="cardNumber"
                    fullWidth
                    value={formData.cardNumber}
                    onChange={handleChange}
                    InputLabelProps={{
                        shrink: false,
                    }}
                    InputProps={{
                        disableUnderline: true,
                        autoComplete: "off",
                        style: {
                            borderRadius: "20px 20px 0px 0px",
                            height: "67px",
                            borderBottom: "0.5px solid #989a9c",
                        },
                        endAdornment: (
                            <InputAdornment position="end">
                                <Box
                                    component="img"
                                    src={visaCard}
                                    alt="visa"
                                    sx={{ width: "40px", height: "40px" }}
                                />
                                <Box
                                    component="img"
                                    src={masterCard}
                                    alt="masterCard"
                                    sx={{ width: "40px", height: "40px" }}
                                />
                            </InputAdornment>
                        ),
                    }}
                />
                <Box sx={{ display: "flex" }}>
                    <TextField
                        placeholder="MM/YY"
                        type="text"
                        className="paymentform-textfield"
                        name="expiryDate"
                        fullWidth
                        value={formData.expiryDate}
                        onChange={handleChange}

                        InputLabelProps={{
                            shrink: false,
                        }}
                        InputProps={{
                            disableUnderline: true,
                            autoComplete: "off",
                            style: {
                                borderRadius: "0px 0px 0px 20px",
                                height: "67px",
                                borderRight: "0.5px solid #989a9c",
                            },
                        }}
                    />
                    <TextField
                        placeholder="CVC"
                        type="text"
                        className="paymentform-textfield"
                        name="cvc"
                        fullWidth
                        value={formData.cvc}
                        onChange={handleChange}
                        InputLabelProps={{
                            shrink: false,
                        }}
                        InputProps={{
                            disableUnderline: true,
                            autoComplete: "off",
                            style: {
                                borderRadius: "0px 0px 20px 0px",
                                height: "67px",
                            },
                            endAdornment: (
                                <InputAdornment position="end">
                                    <CreditCardIcon sx={{ color: "#989a9c" }} />
                                </InputAdornment>
                            ),
                        }}
                    />
                </Box>
                <Typography variant="body1" mb={2} className="paymentform-lebel">
                    Cardholder name
                </Typography>
                <TextField
                    placeholder="Full name on card"
                    type="text"
                    className="paymentform-textfield"
                    name="holderName"
                    fullWidth
                    value={formData.holderName}
                    onChange={handleChange}
                    InputLabelProps={{
                        shrink: false,
                    }}
                    InputProps={{
                        disableUnderline: true,
                        autoComplete: "off",
                    }}
                />
                <Typography variant="body1" mb={2} className="paymentform-lebel">
                    Country or region
                </Typography>
                {/* <FormControl fullWidth> */}
                <TextField
                    select
                    className="paymentform-textfield"
                    placeholder="Select Country or region"
                    variant="filled"
                    SelectProps={{
                        native: true,
                    }}
                    InputLabelProps={{
                        shrink: false,
                    }}
                    InputProps={{
                        disableUnderline: true,
                        autoComplete: "off",
                    }}
                    value={formData.country}
                    onChange={handleChange}
                    fullWidth
                    margin="normal"
                >
                    <option value="" disabled>
                        Select country
                    </option>
                    <option value="USA">USA</option>
                    <option value="Canada">Canada</option>
                </TextField>
                {/* </FormControl> */}

                <Button className="pricing-card-btn" sx={{ width: '100%' }} onClick={handleSubmit}>
                    Submit
                </Button>


                <Typography
                    variant="body1"
                    className="paymentform-confirmation-text"
                >
                    By confirming your subscription, you allow BoldPreso, Inc to <br />
                    charge you for future payments in accordance with their terms. You
                    can always cancel your subscription
                </Typography>
            </Grid>
        </Grid>
    );
}

export default PaymentForm