import React from 'react'
import Navbar from '../Navbar/Navbar';
import { Outlet } from "react-router-dom";
import Footer from '../Footer/Footer';
import { Box } from '@mui/material';

const Layout = () => {
    return (
        <>
            <header>
                <Navbar />
            </header>
            <Box sx={{
                marginTop: { xs: "20px", sm: "20px", md: "80px", lg: "80px" },
            }}>
                <Outlet />
            </Box>
            <Footer />
        </>
    )
}

export default Layout