import React from 'react'
import { Outlet } from "react-router-dom";
// import Footer from '../Footer/Footer';
import { Box } from '@mui/material';
import Sidebar from '../Sidebar/Sidebar'

const PresentationLayout = () => {
    const [open, setOpen] = React.useState(true);
    return (
        <>
            <Box
                sx={{
                    display: "flex",
                    flexDirection: { xs: "column", sm: "column", md: "row", lg: "row" },
                }}
            >
                <Box>
                    <Sidebar open={open} setOpen={setOpen} />
                </Box>
                <Outlet context={[open]} />
            </Box>
            {/* <Footer /> */}
        </>
    )
}

export default PresentationLayout