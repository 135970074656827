import React, { useState, useCallback, useRef, useEffect } from 'react';
import "./MyFile.css";
import { deleteFile, selectFileLoading, selectUrlLoading, sendFileData, sendUrlData, downloadFile } from "./reducer";
import LinkIcon from '../../assets/icon/link.png';
import { Box, Button, TextField, Typography } from '@mui/material';
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import { useDispatch, useSelector } from "react-redux";
import { toast } from 'react-toastify';
import Loader from '../Common/Loader/Loader'
import KnowledgeBase from './KnowledgeBase';
import { getFilesData, selectFilesData, selectFilesLoading } from '../Presentation/reducer';

function MyFile() {
  const [stateBtn, setStateBtn] = useState("file");
  const [urlFileName, setUrlFileName] = useState("");
  const [fileName, setFileName] = useState("");
  const [urlValue, setUrlValue] = useState("");
  const [fileValue, setFileValue] = useState(null);
  const [errorMsg, setErrorMsg] = useState({
    urlFileNameError: "",
    fileNameError: "",
    urlValueError: "",
    fileValueError: ""
  })
  const [open, setOpen] = useState(false);
  const [reload, setReload] = useState(false);
  const fileInputRef = useRef(null);

  const dispatch = useDispatch();
  const fileLoader = useSelector(selectFileLoading);
  const urlLoader = useSelector(selectUrlLoading);
  const filesData = useSelector(selectFilesData);
  const fileDataLoader = useSelector(selectFilesLoading);

  useEffect(() => {
    dispatch(getFilesData());
  }, [reload, dispatch]);

  const handleSubmited = useCallback(async () => {
    let hasError = false;
    const newErrorMsg = {
      urlFileNameError: "",
      fileNameError: "",
      urlValueError: "",
      fileValueError: ""
    };

    if (stateBtn === "url") {
      if (urlFileName.trim() === '') {
        newErrorMsg.urlFileNameError = "Please fill in a filename.";
        hasError = true;
      }
      if (!urlValue) {
        newErrorMsg.urlValueError = "Please fill in a URL.";
        hasError = true;
      }

      setErrorMsg(newErrorMsg);

      if (!hasError) {
        const data = {
          url: urlValue,
          file_name: urlFileName,
        };
        const resUrl = await dispatch(sendUrlData(data));
        if (resUrl.type === 'post/sendUrlData/fulfilled') {
          toast.success(resUrl.payload.message);
          setUrlValue("");
          setUrlFileName("");
        } else {
          const urlErrorMsg = resUrl.payload?.detail || "Failed to upload URL.";
          toast.error(urlErrorMsg);
        }
      }
    } else if (stateBtn === "file") {
      if (fileName.trim() === '') {
        newErrorMsg.fileNameError = "Please fill in a filename.";
        hasError = true;
      }
      if (!fileValue) {
        newErrorMsg.fileValueError = "Please select a file.";
        hasError = true;
      }

      setErrorMsg(newErrorMsg);

      if (!hasError) {
        const data = {
          file_name: fileName,
          file: fileValue,
        };
        const res = await dispatch(sendFileData(data));
        if (res.type === 'post/sendFileData/fulfilled') {
          toast.success(res.payload.message);
          if (fileInputRef.current) {
            fileInputRef.current.value = "";
          }
          setFileValue(null);
          setFileName("");
        } else {
          const fileErrorMsg = res.payload?.detail || "Failed to upload File.";
          toast.error(fileErrorMsg);
        }
      }
    }
  }, [stateBtn, urlValue, urlFileName, fileValue, fileName, dispatch]);

  const handleFileChange = useCallback((e) => {
    setFileValue(e.target.files[0]);
  }, []);


  const handleOpen = () => {
    setOpen(!open);
  }

  const hadleFileDelete = async (name) => {
   const res = await dispatch(deleteFile(name));

   if (res.type === 'delete/file/fulfilled') {
    setReload(!reload);
    toast.success("File Deleted successfully.");
  } else {
    toast.error("File not deleted.");
  }
    
  }

  const handleDownloadFile = async (file) => {
    const res = await dispatch(downloadFile(file));
    if (res.type === 'download/file/fulfilled') {
      toast.success("File Downloaded successfully.");
    } else {
      toast.error("Webpage or youtube type files cannot be downloaded.");
    }
  }

  return (
    <Box className="file-container" sx={{ position: 'relative' }}>
      {fileLoader || urlLoader || fileDataLoader ? <Box className='loader-container'><Loader /></Box> : <></>}
      {open ?
        <Box sx={{
          width: { xs: "340px", sm: "500px", md: "500px", lg: "500px" },
          p: 2,
          opacity: fileLoader || urlLoader ? 0.5 : 1
        }}>
          <Box mb={1} className="file-headerBox">
            <Typography className='file-TypoText' variant='h6'>Add files</Typography>
            <Typography onClick={handleOpen} className='file-addFile' variant='h6'>My files</Typography>
          </Box>
          <Box sx={{
            width: "100%",
            height: { xs: "130px", sm: "150px", md: "160px", lg: "160px" },
            display: "flex"
          }}>
            <Box
              className="file-iconBox" onClick={() => setStateBtn("file")}
              sx={{
                border: stateBtn === "file" ? "2px solid #fff" : "none",
              }}>

              <InsertDriveFileOutlinedIcon sx={{
                fontSize: "48px",
                color: stateBtn === "file" ? "#fff" : "rgba(255, 255, 255, 0.5)"
              }} />
              <Typography variant='h6' className='IconText' sx={{
                color: stateBtn === "file" ? "#fff" : "rgba(255, 255, 255, 0.5)"
              }}  >File</Typography>

            </Box>
            <Box sx={{ width: "4%", height: "100%" }}></Box>
            <Box
              className="file-iconBox" onClick={() => setStateBtn("url")}
              sx={{
                border: stateBtn === "url" ? "2px solid #fff" : "none",
              }}>

              <img src={LinkIcon} height={"42px"} width={"54px"} alt='Linkicon' />
              <Typography variant='h6' className='IconText' sx={{
                color: stateBtn === "url" ? "#fff" : "rgba(255, 255, 255, 0.5)"
              }} >URL</Typography>

            </Box>

          </Box>
          <Box sx={{
            width: "100%",

          }}>

            {stateBtn === "url" ?
              <Box mt={3} mb={1}>
                <Typography className='textFeildLabel' variant='h6'>File Name</Typography>
                <TextField
                  placeholder="Enter File Name"
                  className="fileTextFiled"
                  variant="filled"
                  InputLabelProps={{
                    shrink: false,
                  }}
                  InputProps={{
                    disableUnderline: true,
                    autoComplete: "off",
                    style: { height: "75px", width: "100%" },
                  }}
                  type='text'
                  fullWidth
                  value={urlFileName}
                  onChange={(e) => setUrlFileName(e.target.value)}
                />
                <Typography
                  variant="body1"
                  pl={0.5}
                  my={0.5}
                  className="errorField"
                >
                  {errorMsg.urlFileNameError}
                </Typography>
                <Typography className='textFeildLabel' variant='h6' mt={2}>URL</Typography>
                <TextField
                  placeholder="Enter your URL"
                  className="fileTextFiled"
                  variant="filled"
                  InputLabelProps={{
                    shrink: false,
                  }}
                  InputProps={{
                    disableUnderline: true,
                    autoComplete: "off",
                    style: { height: "75px", width: "100%" },
                  }}
                  type='url'
                  fullWidth
                  value={urlValue}
                  onChange={(e) => setUrlValue(e.target.value)}
                />
                <Typography
                  variant="body1"
                  pl={0.5}
                  my={0.5}
                  className="errorField"
                >
                  {errorMsg.urlValueError}
                </Typography>
              </Box> : <Box mt={3} mb={1}>
                <Typography className='textFeildLabel' variant='h6'>File Name</Typography>
                <TextField
                  placeholder="Enter File Name"
                  className="fileTextFiled"
                  variant="filled"
                  InputLabelProps={{
                    shrink: false,
                  }}
                  InputProps={{
                    disableUnderline: true,
                    autoComplete: "off",
                    style: { height: "75px", width: "100%" },
                  }}
                  type='text'
                  fullWidth
                  value={fileName}
                  onChange={(e) => setFileName(e.target.value)}
                />
                <Typography
                  variant="body1"
                  pl={0.5}
                  my={0.5}
                  className="errorField"
                >
                  {errorMsg.fileNameError}
                </Typography>
                <Typography className='textFeildLabel' variant='h6' mt={2}>Upload File</Typography>
                <TextField
                  inputRef={fileInputRef}
                  placeholder="Upload File from your device"
                  className={`fileTextFiled ${fileValue ? 'file-selected' : ''}`}
                  variant="filled"
                  sx={{
                    "&MuiInputBase-root>.MuiInputBase-input": {
                      color: 'red !important',
                      bgcolor: "blue !important"
                    },

                  }}
                  InputLabelProps={{
                    shrink: false,
                  }}
                  InputProps={{
                    disableUnderline: true,
                    autoComplete: "off",
                    style: {
                      height: "75px", width: "100%",
                      color: "red !important"
                    },
                  }}
                  type='file'
                  fullWidth
                  // value={fileValue}
                  onChange={handleFileChange}
                />
                <Typography
                  variant="body1"
                  pl={0.5}
                  my={0.5}
                  className="errorField"
                >
                  {errorMsg.fileValueError}
                </Typography>
              </Box>}
            <Button className='fileSubmitBtn' onClick={handleSubmited}>Create</Button>
          </Box>
        </Box>
        : <KnowledgeBase handleOpen={handleOpen} filesData={filesData} hadleFileDelete={hadleFileDelete} handleDownloadFile={handleDownloadFile} />}
    </Box>

  )
}

export default MyFile