import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import * as API from "../../api/index";

const initialState = {
    email: "",
    authToken: null,
    refreshToken: null,
    isLoggedIn: false,
    user_id: "",
    full_name: "",
    is_premium: false,
    subscription_start_date: null,
    templates: [],
    ui: {
        getuser: {
            loading: false
        },
        templates: {
            status: 'idle',
            loading: false,
        },
    }
};

const createUser = createAsyncThunk("auth/createUser", async (params) => {
    const res = await API.createUserData(params);
    return res.data;
});

const getUser = createAsyncThunk("get/user", async () => {
    const res = await API.getUserData();
    return res.data;
});

const getTemplates = createAsyncThunk("get/templates", async () => {
    const res = await API.getTemplates();
    return res.data;
});


export const authSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        setIsLoggedIn: (state, action) => {
            state.isLoggedIn = action.payload;
        },
        setToken: (state, action) => {
            state.authToken = action.payload;
        },
        setRefreshToken: (state, action) => {
            state.refreshToken = action.payload;
        },
        logout: (state) => {
            return {
                ...initialState,
            };
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getUser.pending, (state, action) => {
                state.ui.getuser.loading = true;
            })
            .addCase(getUser.fulfilled, (state, action) => {
                const name = action.payload.full_name.split("@");
                state.ui.getuser.loading = false;
                state.email = action.payload.email;
                state.user_id = action.payload.user_id;
                state.full_name = name[0];
                state.is_premium = action.payload.is_premium;
                state.subscription_start_date = action.payload.subscription_start_date;
            })
            .addCase(getUser.rejected, (state, action) => {
                state.ui.getuser.loading = false;
            });
        builder
            .addCase(getTemplates.pending, (state, action) => {
                state.ui.templates.status = 'pending';
                state.ui.templates.loading = true;
            })
            .addCase(getTemplates.fulfilled, (state, action) => {
                state.ui.templates.status = 'success';
                state.ui.templates.loading = false;
                state.templates = action.payload;
            })
            .addCase(getTemplates.rejected, (state, action) => {
                state.ui.templates.status = 'rejected';
                state.ui.templates.loading = false;
            });
    }
});

export const { logout, setIsLoggedIn, setToken, setRefreshToken } = authSlice.actions;
export const selectIsLoggedIn = (state) => state.auth.isLoggedIn;
export const getThumbnails = (state) => state.auth.thumbnail;
export const getTemplate = (state) => state.auth.templates;
export const getTemplateStatus = (state) => state.auth.ui.templates.status;
export default authSlice.reducer;
export {
    createUser,
    getUser,
    getTemplates,
}
