import React from "react";
import "./Card.css";
import { Card, CardContent, Typography } from "@mui/material";

const SpinCard = ({ data, show }) => {
  return (
    <Card
      sx={{
        width: {
          xs: data.width.xs,
          sm: data.width.sm,
          md: data.width.md,
          lg: data.width.lg,
        },
        minHeight: {
          xs: data.height.xs,
          sm: data.height.sm,
          md: data.height.md,
          lg: data.height.lg,
        },
        borderRadius: "15px",
        position: {
          xs: "static",
          sm: "static",
          md: "absolute",
          lg: "absolute",
        },
        display: show ? "block" : "none",
        // transform: { xs: `rotate(0deg)`, sm: `rotate(0deg)`, md: `rotate(${data.rotate}deg)`, lg: `rotate(${data.rotate}deg)` },
        background: data.color,
        "--rotate": `${data.rotate}deg`,
        "--translate": `${data.translate}`,
      }}
      className={show ? "cards-container" : ""}
    >
      <CardContent sx={{ p: 4 }}>
        <Typography className="card-title">{data.title}</Typography>
        <Typography className="card-content">{data.content}</Typography>
      </CardContent>
    </Card>
  );
};

export default SpinCard;
