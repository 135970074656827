import React from "react";
import { Box, Modal, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import failedImg from "../../../assets/failed_icon.svg";

function Failure({ open, handleClose, message, errorMessage }) {

  let errMsg =
    "Maximum of 10 pages allowed. To add more slides, consider upgrading the plan.";

  return (
    <>
      {/* Failed modal screen */}
      <Modal open={open} onClose={handleClose}>
        <Box
          className="modal-box"
          sx={{ width: { xs: "320px", sm: "480px", md: "540px", lg: "540px" } }}
        >
          {errorMessage === errMsg ? (
            <></>
          ) : (
            <img
              src={failedImg}
              alt="failed-msg"
              style={{ width: "72px", height: "72px" }}
            />
          )}
          <Typography variant="h4"> {message || errorMessage === errMsg ? "" : "Failed"}</Typography>
          <Typography variant="h5" sx={{ textAlign: "center" }}>
            {message
              ? message
              : errorMessage
                ? errorMessage.length > 100
                  ? "Sorry but failed, Please try again"
                  : errorMessage
                : "Sorry but failed, Please try again"}
          </Typography>
          <Box className="CloseIcon" onClick={handleClose}>
            <CloseIcon sx={{ fontSize: 15 }} />
          </Box>
        </Box>
      </Modal>
    </>
  );
}

export default Failure;
