import * as React from "react";
import {
    Box,
    Button,
    Drawer,
    List,
    ListItem,
    ListItemText,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { NavLink } from "react-router-dom";
import boldpreso_logo from "../../assets/boldpreso_logo.png";
import BorderColorOutlined from "@mui/icons-material/BorderColorOutlined";
import AccessTimeOutlined from "@mui/icons-material/AccessTimeOutlined";
import CreditCardOutlined from "@mui/icons-material/CreditCardOutlined";
import ChatBubbleOutlineOutlined from "@mui/icons-material/ChatBubbleOutlineOutlined";
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import { useDispatch } from "react-redux";
import { logout } from "../Login/reducer";
import { signOut , getAuth} from "firebase/auth";

function SideDrawer() {
    const [state, setState] = React.useState({
        left: false,
    });

    const dispatch = useDispatch();
    const auth = getAuth();
    const handleLogout = () => {
        signOut(auth).then(() => {
            // Sign-out successful.
            dispatch(logout());
            console.log("Signed out successfully")
        }).catch((error) => {
            // An error happened.
        });

    };

    const toggleDrawer = (open) => (event) => {
        if (
            event.type === "keydown" &&
            (event.key === "Tab" || event.key === "Shift")
        ) {
            return;
        }
        setState({ ...state, left: open });
    };

    return (
        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            <Button onClick={toggleDrawer(true)} className="drawer-menu-icon">
                <MenuIcon />
            </Button>
            <Drawer
                anchor="left"
                open={state.left}
                onClose={toggleDrawer(false)}
                className="drawer-container"
            >
                <Box
                    sx={{ width: 270 }}
                    role="presentation"
                    onClick={toggleDrawer(false)}
                    onKeyDown={toggleDrawer(false)}
                >
                    <Box className="d-flex" my={3}>
                        <img src={boldpreso_logo} alt="logo" width={160} height={48} />
                    </Box>

                    <>
                        <List>
                            <NavLink
                                to={"/presentation"}
                                end
                                className={({ isActive }) =>
                                    isActive ? "sidemenu-active" : "sidemenu-inactive"
                                }
                            >
                                <ListItem className="sidemenu-list">
                                    <AccessTimeOutlined sx={{ fontSize: 25 }} />
                                    <ListItemText primary="Recent" />
                                </ListItem>
                            </NavLink>
                            <NavLink
                                to={"/presentation/chat"}
                                className={({ isActive }) =>
                                    isActive ? "sidemenu-active" : "sidemenu-inactive"
                                }
                            >
                                <ListItem className="sidemenu-list">
                                    <ChatBubbleOutlineOutlined sx={{ fontSize: 25 }} />
                                    <ListItemText primary="Talk with boldpreso AI" />
                                </ListItem>
                            </NavLink>
                            <NavLink
                                to={"/presentation/create"}
                                className={({ isActive }) =>
                                    isActive ? "sidemenu-active" : "sidemenu-inactive"
                                }
                            >
                                <ListItem className="sidemenu-list">
                                    <BorderColorOutlined sx={{ fontSize: 25 }} />
                                    <ListItemText primary="Make presentation" />
                                </ListItem>
                            </NavLink>
                            <NavLink
                                to={"/presentation/subscription"}
                                className={({ isActive }) =>
                                    isActive ? "sidemenu-active" : "sidemenu-inactive"
                                }
                            >
                                <ListItem className="sidemenu-list">
                                    <CreditCardOutlined sx={{ fontSize: 25 }} />
                                    <ListItemText primary="Payment and Subscription" />
                                </ListItem>
                            </NavLink>
                            <NavLink
                                to={"/presentation/MyFile"}
                                className={({ isActive }) =>
                                    isActive ? "sidemenu-active" : "sidemenu-inactive"
                                }
                            >
                                <ListItem className="sidemenu-list">
                                    <InsertDriveFileOutlinedIcon sx={{ fontSize: 25 }} />
                                    <ListItemText primary="Knowledge base" />
                                </ListItem>
                            </NavLink>
                        </List>
                        <List>
                            <Button className="logout-btn" onClick={handleLogout}>
                                Logout
                            </Button>
                        </List>
                    </>
                </Box>
            </Drawer>
        </Box>
    );
}

export default SideDrawer;
