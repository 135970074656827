import axios from "axios";
import { setRefreshToken, setToken } from "../components/Login/reducer";

const baseURL = process.env.REACT_APP_API_URL;

const api = axios.create({
    baseURL,
});

// Add a request interceptor
export function authTokenInterceptor(store) {
    api.interceptors.request.use(
        (config) => {
            const token = store.getState().auth.authToken;
            if (token) {
                config.headers.Authorization = `Bearer ${token}`;
            }
            return config;
        },
        (error) => Promise.reject(error)
    );

    // Add a response interceptor
    api.interceptors.response.use(
        (response) => response,
        async (error) => {
            const originalRequest = error.config;

            if (error.response.status === 401 && !originalRequest._retry) {
                originalRequest._retry = true;
                try {
                    const refreshToken = store.getState().auth.refreshToken;
                    const apiKey = process.env.REACT_APP_API_KEY;
                    const data = {
                        grant_type: "refresh_token",
                        refresh_token: refreshToken,
                    };
                    const response = await axios.post(
                        `https://securetoken.googleapis.com/v1/token?key=${apiKey}`,
                        data
                    );
                    const { access_token, refresh_token } = response.data;
                    store.dispatch(setToken(access_token));
                    store.dispatch(setRefreshToken(refresh_token));
                    // Retry the original request with the new token
                    originalRequest.headers.Authorization = `Bearer ${access_token}`;
                    return axios(originalRequest);
                } catch (error) {
                    // Handle refresh token error or redirect to login
                    console.log("Error :", error);
                }
            }
            return Promise.reject(error);
        }
    );
}

export default api;
