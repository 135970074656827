import React from "react";
import "./Sidebar.css";
import { styled } from "@mui/material/styles";
import {
  Box,
  List,
  IconButton,
  ListItem,
  ListItemText,
  Stack,
  Divider
} from "@mui/material";
import MuiDrawer from "@mui/material/Drawer";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { NavLink } from "react-router-dom";
import SideDrawer from "../Sidebar/SideDrawer";
import logo from "../../assets/boldpreso_logo.png";
import LogoutIcon from "@mui/icons-material/Logout";
import BorderColorOutlined from "@mui/icons-material/BorderColorOutlined";
import AccessTimeOutlined from "@mui/icons-material/AccessTimeOutlined";
import CreditCardOutlined from "@mui/icons-material/CreditCardOutlined";
import ChatBubbleOutlineOutlined from "@mui/icons-material/ChatBubbleOutlineOutlined";
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import { useDispatch } from "react-redux";
import { logout } from "../Login/reducer";
import { signOut, getAuth } from "firebase/auth";
const drawerWidth = 300;

const openedMixin = (theme) => ({
  width: drawerWidth,
  background: "#131619",
  // borderRight: "1px solid #D286FF",
  // transition: theme.transitions.create("width", {
  //   easing: theme.transitions.easing.sharp,
  //   duration: theme.transitions.duration.enteringScreen,
  // }),
  transition: "ease-in-out 0.7s",
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  // transition: theme.transitions.create("width", {
  //   easing: theme.transitions.easing.sharp,
  //   duration: theme.transitions.duration.leavingScreen,
  // }),
  transition: "1s",
  background: "#131619",
  // borderRight: "1px solid #D286FF",
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export default function Sidebar({ open, setOpen }) {
  const dispatch = useDispatch();
  const auth = getAuth();
  const handleLogout = () => {
    signOut(auth)
      .then(() => {
        // Sign-out successful.
        dispatch(logout());
        console.log("Signed out successfully");
      })
      .catch((error) => {
        // An error happened.
      });
  };
  const handleDrawerOpen = () => {
    setOpen(!open);
  };

  return (
    <>
      <Box className="sidebar-container">
        <Drawer
          variant="permanent"
          open={open}
          className="sidebar-drawer"
          sx={{
            display: {
              md: "block",
              lg: "block",
              xl: "block",
              sm: "none",
              xs: "none",
            },
          }}
        >
          <DrawerHeader className="sidebar-header">
            <img
              src={logo}
              alt="logo"
              width={175}
              height={55}
              style={{
                display: open ? "block" : "none",
              }}
            />
          </DrawerHeader>
          {open && <Divider className="sidebar-divider" />}
          <List className="menu-list">
            <NavLink
              to={"/presentation"}
              end
              className={({ isActive }) =>
                isActive ? "menu-active" : "sideMenu-inactive"
              }
            >
              <ListItem sx={{ display: "flex", gap: "8px" }}>
                <AccessTimeOutlined sx={{ fontSize: 25 }} />
                <ListItemText className="listText" primary="Recent" />
              </ListItem>
            </NavLink>
            <NavLink
              to={"/presentation/chat"}
              className={({ isActive }) =>
                isActive ? "menu-active" : "sideMenu-inactive"
              }
            >
              <ListItem sx={{ display: "flex", gap: "8px" }}>
                <ChatBubbleOutlineOutlined sx={{ fontSize: 25 }} />
                <ListItemText
                  className="listText"
                  primary="Talk with boldpreso AI"
                />
              </ListItem>
            </NavLink>
            <NavLink
              to={"/presentation/create"}
              className={({ isActive }) =>
                isActive ? "menu-active" : "sideMenu-inactive"
              }
            >
              <ListItem sx={{ display: "flex", gap: "8px" }}>
                <BorderColorOutlined sx={{ fontSize: 25 }} />
                <ListItemText
                  className="listText"
                  primary="Make presentation"
                />
              </ListItem>
            </NavLink>
            <NavLink
              to={"/presentation/subscription"}
              className={({ isActive }) =>
                isActive ? "menu-active" : "sideMenu-inactive"
              }
            >
              <ListItem sx={{ display: "flex", gap: "8px" }}>
                <CreditCardOutlined sx={{ fontSize: 25 }} />
                <ListItemText
                  className="listText"
                  primary="Payment and Subscription"
                />
              </ListItem>
            </NavLink>
            <NavLink
              to={"/presentation/MyFile"}
              className={({ isActive }) =>
                isActive ? "menu-active" : "sideMenu-inactive"
              }
            >
              <ListItem sx={{ display: "flex", gap: "8px" }}>
                <InsertDriveFileOutlinedIcon sx={{ fontSize: 25 }} />
                <ListItemText
                  className="listText"
                  primary="Knowledge base"
                />
              </ListItem>
            </NavLink>
            <NavLink className="sideMenu-inactive" onClick={handleLogout}>
              <ListItem sx={{ display: "flex", gap: "8px" }}>
                <LogoutIcon sx={{ fontSize: 28 }} />
                <ListItemText className="listText" sx={{paddingLeft:"12px"}} primary="Logout" />
              </ListItem>
            </NavLink>
          </List>
        </Drawer>
        <Box
          className="sidemenu-circle"
          sx={{
            left: open ? "284px" : "50px",
            transition: open ? "ease-in-out 0.7s" : "1s",
          }}
        >
          <IconButton onClick={handleDrawerOpen} sx={{ color: "#fff" }}>
            {open === true ? (
              <ArrowBackIcon style={{ fontSize: "20px" }} />
            ) : (
              <ArrowForwardIcon style={{ fontSize: "20px" }} />
            )}
          </IconButton>
        </Box>
      </Box>
      <Stack
        justifyContent={"center"}
        sx={{
          display: {
            md: "none",
            lg: "none",
            xl: "none",
            sm: "flex",
            xs: "flex",
          },
          p: 1,
          pt: 3,
        }}
      >
        <SideDrawer />
      </Stack>
    </>
  );
}
