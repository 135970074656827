import React, { useState, useEffect } from "react";
import { TextField, Grid, Typography, MenuItem, Select, OutlinedInput } from "@mui/material";
import { Container, Box, Button } from "@mui/material";
import "./Form.css";
import Success from "../Common/PresentationToast/Success";
import { useDispatch, useSelector } from "react-redux";
import {
  makePresentation,
  selectStatus,
  makepresentationLoader,
  PptErrorMessage,
  pptLimit,
  getPPTLimit,
  getFilesData,
  selectFilesLoading,
  selectFilesData,
} from "../Presentation/reducer";
import { getTemplate } from "../Login/reducer";
import Failure from "../Common/PresentationToast/Failure";
import { PresentationFormValidation } from "../Common/Validation/Validation";
import { useNavigate } from "react-router-dom";
import Loader from "../Common/Loader/Loader";

const Form = () => {
  const [topic, setTopic] = useState("");
  const [number_of_pages, setNumberOfPages] = useState("");
  const [templateName, setTemplateName] = useState("");
  const [instructions, setInstructions] = useState("");
  const [negative_prompt, setPrompt] = useState("");
  const [file_name, setFileName] = useState("");
  const [topicError, setTopicError] = useState("");
  const [number_of_pagesError, setNumberOfPagesError] = useState("");
  const [templateNameError, setTemplateNameError] = useState("");
  const [instructionsError, setInstructionsError] = useState("");
  // const [negative_promptError, setPromptError] = useState("");

  const dispatch = useDispatch();
  const templates = useSelector(getTemplate);
  const status = useSelector(selectStatus);
  const loader = useSelector(makepresentationLoader);
  const errorMessage = useSelector(PptErrorMessage);
  const PptLimit = useSelector(pptLimit);
  const premium_user = useSelector((state) => state.auth.is_premium);
  const filesLoader = useSelector(selectFilesLoading);
  const filesData = useSelector(selectFilesData);

  const [open, setOpen] = React.useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getPPTLimit());
    dispatch(getFilesData());
  }, [dispatch]);

  const pageOnChange = (e) => {
    setNumberOfPages(parseInt(e.target.value));
  };
  const handleSubmit = async () => {
    const validate = PresentationFormValidation(
      topic,
      instructions,
      number_of_pages,
      negative_prompt,
      templateName,
      setTopicError,
      setInstructionsError,
      setNumberOfPagesError,
      // setPromptError,
      setTemplateNameError
    );
    if (validate) {
      setOpen(true);
      const data = {
        topic,
        instructions,
        number_of_pages,
        negative_prompt,
        templateName,
        file_name
      };
      const res = await dispatch(makePresentation(data));
      if (res.type === "make/presentation/fulfilled") {
        setTopic("");
        setNumberOfPages("");
        setTemplateName("");
        setInstructions("");
        setPrompt("");
        setFileName("");
        setTopicError("");
        setNumberOfPagesError("");
        setTemplateNameError("");
        setInstructionsError("");
        // setPromptError("");
      }
    }
  };
  const handleClose = () => {
    if (status === "success") {
      setOpen(false);
      navigate("/presentation");
    } else {
      setOpen(false);
    }
  };

  return (
    <>
      {filesLoader ? <Loader/> :
        <Container sx={{ p: "30px", mt: "30px" }}>
          <Box className="presentation-header">
            <Typography
              variant="h6"
              sx={{ textAlign: "right" }}
              className="presentation-subhead"
            >
              {PptLimit !== -1 && `Available limit: ${PptLimit}`}
            </Typography>
            <Typography variant="h6" className="presentation-head">
              Enter Presentation Details
            </Typography>
          </Box>
          <Grid container className="presentation-form">
            <Grid item xs={12} sm={12} md={5.5} lg={5.5}>
              <Typography variant="body1" className="textfield-label">
                Topic
              </Typography>
              <TextField
                placeholder="Enter topic here"
                className="textfield"
                variant="filled"
                value={topic}
                InputLabelProps={{
                  shrink: false,
                }}
                InputProps={{
                  disableUnderline: true,
                  autoComplete: "off",
                  style: { height: "100px" },
                }}
                onChange={(e) => setTopic(e.target.value)}
                fullWidth
                margin="normal"
              />
              <Typography
                variant="body1"
                pl={0.5}
                my={0.5}
                className="errorField"
              >
                {topicError}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={1} lg={1}></Grid>
            <Grid item xs={12} sm={12} md={5.5} lg={5.5}>
              <Typography variant="body1" className="textfield-label">
                Number of pages
              </Typography>
              <TextField
                value={number_of_pages}
                placeholder="Enter number of pages"
                type="number"
                variant="filled"
                InputLabelProps={{
                  shrink: false,
                }}
                InputProps={{
                  disableUnderline: true,
                  autoComplete: "off",
                  style: { height: "100px" },
                }}
                className="textfield"
                onChange={pageOnChange}
                fullWidth
                margin="normal"
              />
              <Typography
                variant="body1"
                pl={0.5}
                my={0.5}
                className="errorField"
              >
                {number_of_pagesError}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={5.5} lg={5.5}>
              <Typography variant="body1" className="textfield-label">
                Instruction
              </Typography>
              <TextField
                placeholder="Enter any special instructions"
                className="textfield"
                variant="filled"
                InputLabelProps={{
                  shrink: false,
                }}
                InputProps={{
                  disableUnderline: true,
                  autoComplete: "off",
                  style: { height: "100px" },
                }}
                value={instructions}
                onChange={(e) => setInstructions(e.target.value)}
                fullWidth
                margin="normal"
              />
              <Typography
                variant="body1"
                pl={0.5}
                my={0.5}
                className="errorField"
              >
                {instructionsError}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={1} lg={1}></Grid>
            <Grid item xs={12} sm={12} md={5.5} lg={5.5}>
              <Typography mb={1.5} variant="body1" className="textfield-label">
                Template name
              </Typography>
              <Select
                displayEmpty
                placeholder="Select a template"
                className="selectTextfield"
                value={templateName}
                onChange={(e) => setTemplateName(e.target.value)}
                fullWidth
                input={<OutlinedInput />}
                sx={{
                  "& .MuiOutlinedInput-notchedOutline": {
                    border: "none",
                  },
                  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    border: "2px solid #d286ff !important",
                  }
                }}
                renderValue={(selected) => {
                  if (selected === '') {
                     return <em style={{ color: "#ffffff40",fontStyle:'normal',fontFamily:'"DM Sans", sans-serif'}}>Select a template</em>;
                  }

                    return selected;
                  }}
                MenuProps={{
                  PaperProps: {
                    style: {
                      maxHeight: 200,
                      width: 200,
                      backgroundColor: '#1f2327',
                      color: '#ffffff40',
                    },
                }}}
                inputProps={{ 'aria-label': 'Without label' }}
              >
                <MenuItem value="" disabled style={{ opacity: "0.5 !important" }}>
                  <em style={{ fontStyle:'normal',fontFamily:'"DM Sans", sans-serif'}}>Select a template</em>
                </MenuItem>
                {templates.map((temp, i) => (
                  <MenuItem
                    value={temp.name}
                    key={i}
                    sx={{
                      display: !premium_user && temp.is_premium ? "none" : "block",
                      fontFamily: '"DM Sans", sans-serif !important',
                      "&:hover": {
                        backgroundColor: '#d286ff',
                        color: '#fff'
                      },
                      "&.Mui-selected": {
                        backgroundColor: '#d286ff',
                        color: '#fff'
                      }
                    }}
                  >
                    {temp.name}
                  </MenuItem>
                ))}
              </Select>
              <Typography
                variant="body1"
                pl={0.5}
                my={0.5}
                className="errorField"
              >
                {templateNameError}
              </Typography>
            </Grid>
            {/* <Grid item xs={12} sm={12} md={5.5} lg={5.5}>
              <Typography variant="body1" className="textfield-label">
                Negative Prompt
              </Typography>
              <TextField
                placeholder="Enter a negative prompt (optional)"
                className="textfield"
                variant="filled"
                value={negative_prompt}
                InputLabelProps={{
                  shrink: false,
                }}
                InputProps={{
                  disableUnderline: true,
                  autoComplete: "off",
                  style: { height: "100px" },
                }}
                onChange={(e) => setPrompt(e.target.value)}
                fullWidth
                margin="normal"
              />
              <Typography
                variant="body1"
                pl={0.5}
                my={0.5}
                className="errorField"
              >
                {negative_promptError}
              </Typography>
            </Grid> */}
            <Grid item xs={12} sm={12} md={5.5} lg={5.5}>
              <Typography mb={1.5} variant="body1" className="textfield-label">
                URL or Doc
              </Typography>
              <Select
                displayEmpty
                placeholder="URL or Doc"
                className="selectTextfield"
                value={file_name}
                onChange={(e) =>  setFileName(e.target.value)}
                fullWidth
                input={<OutlinedInput />}
                sx={{
                  "& .MuiOutlinedInput-notchedOutline": {
                    border: "none",
                  },
                  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    border: "2px solid #d286ff !important",
                  }
                }}
                renderValue={(selected) => {
                  if (selected === '') {
                     return <em style={{ color: "#ffffff40",fontStyle:'normal',fontFamily:'"DM Sans", sans-serif'}}>URL or Doc</em>;
                  }

                    return selected;
                  }}
                MenuProps={{
                  PaperProps: {
                    style: {
                      maxHeight: 200,
                      width: 200,
                      backgroundColor: '#1f2327',
                      color: '#ffffff40',
                    },
                }}}
                inputProps={{ 'aria-label': 'Without label' }}
              >
                <MenuItem value="" disabled style={{ opacity: "0.5 !important" }}>
                  <em style={{ fontStyle:'normal',fontFamily:'"DM Sans", sans-serif'}}>URL or Doc</em>
                </MenuItem>
                {filesData.map((file) => (
                  <MenuItem
                    value={file.name}
                    key={file.id}
                    sx={{
                      fontFamily: '"DM Sans", sans-serif !important',
                      "&:hover": {
                        backgroundColor: '#d286ff',
                        color: '#fff'
                      },
                      "&.Mui-selected": {
                        backgroundColor: '#d286ff',
                        color: '#fff'
                      }
                    }}
                  >
                    {file.name}
                  </MenuItem>
                ))}
              </Select>
              {/* <Typography
                variant="body1"
                pl={0.5}
                my={0.5}
                className="errorField"
              >
                {file_nameError}
              </Typography> */}
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} className="btn-box">
              <Button
                variant="contained"
                className="create-btn"
                onClick={handleSubmit}
              >
                Create Slide
              </Button>
            </Grid>
          </Grid>
        </Container>
      }
      {status === "success" || status === "pending" ? (
        <Success
          open={open}
          handleClose={handleClose}
          status={status}
          loader={loader}
        />
      ) : (
        <Failure
          open={open}
          handleClose={handleClose}
          status={status}
          loader={loader}
          errorMessage={errorMessage}
        />
      )}
    </>
  );
};

export default Form;
