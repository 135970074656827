import { configureStore } from "@reduxjs/toolkit";
import rootReducer from "../reducer/index";
import persistStore from "redux-persist/es/persistStore";
import {   
    FLUSH,
    REHYDRATE,
    PAUSE,
    PERSIST,
    PURGE,
    REGISTER,
} from 'redux-persist';

export function configureAppStore() {
    const store = configureStore({
        reducer: rootReducer,
        devTools: process.env.NODE_ENV !== 'production',
        middleware: (getDefaultMiddleware) =>
            getDefaultMiddleware({
                serializableCheck: {
                    ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
                },
            }),
    });
    let persistor = persistStore(store);
    return { store, persistor };
}
