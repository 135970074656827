import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { Provider as StoreProvider } from "react-redux";
import { configureAppStore } from "./redux/store";
import { PersistGate } from "redux-persist/integration/react";
import { authTokenInterceptor } from "./api/axiosInstance";
export const configureStore = configureAppStore();
authTokenInterceptor(configureStore.store);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <StoreProvider store={configureStore.store}>
      <PersistGate loading={null} persistor={configureStore.persistor}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </PersistGate>
    </StoreProvider>
  </React.StrictMode>
);
