import React, { useState } from "react";
import {
  Box,
  Grid,
  TextField,
  IconButton,
  InputAdornment,
} from "@mui/material";

import "./Footer.css";

import boldpreso_logo from "../../assets/boldpreso_logo.png";
import footer_arrow from "../../assets/footer_arrow.png";
// import music from "../../assets/music.svg";
// import youtube from "../../assets/youtube.svg";
// import instagram from "../../assets/instagram.svg";
// import facebook from "../../assets/facebook.svg";
import twitter from "../../assets/twitter.svg";
import CallMadeRoundedIcon from "@mui/icons-material/CallMadeRounded";
import { Link, useNavigate } from "react-router-dom";

function Footer() {
  const [email, setEmail] = useState("");
  const navigate = useNavigate();

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const goToSignup = () => {
    console.log(email);
    navigate("/signup", { state: { email } });
  };

  return (
    <footer className="footer">
      <Grid container style={{ padding: "15px 30px" }} spacing={2}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <img src={boldpreso_logo} alt="logo" width={140} height={40} />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={4}>
          <Box>
            <p className="footer-menu-title">COMPANY</p>
            <div className="footer-menu-subTitles">
              <Link to="/price">
                <p style={{ padding: "32px 0" }}>Pricing</p>
              </Link>
              <Link to="/feature">
                <p>Features</p>
              </Link>
            </div>
          </Box>
        </Grid>
        {/* <Grid item xs={12} sm={6} md={6} lg={6}></Grid> */}
        {/* <Grid item xs={12} sm={6} md={3} lg={3}>
          <Box>
            <p className="footer-menu-title">RESOURES</p>
            <div className="footer-menu-subTitles">
              <p style={{ padding: "32px 0" }}>Blog</p>
            </div>
          </Box>
        </Grid> */}
        <Grid item xs={12} sm={6} md={4} lg={4}>
          <Box>
            <p className="footer-menu-title">CONTACT US</p>
            <div className="footer-menu-subTitles">
              {/* <p style={{ padding: "32px 0" }}>
                61 Jay Street, Brooklyn,
                <br /> NY 21 Unites State
              </p> */}
              <div style={{marginTop:'32px'}}>
                <a href="mailto:hello@boldpreso.com" style={{textDecoration:'none',color:'#fff'}}>hello@boldpreso.com</a>
                {/* <p>+374657865</p> */}
              </div>
            </div>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={4}>
          <Box>
            <Box display={"flex"} gap={0.5} alignItems={"center"}>
              <p className="footer-menu-title">START NOW</p>
              <img
                src={footer_arrow}
                alt="footer_arrow_logo"
                width="17px"
                height="17px"
                style={{ marginBottom: "3px" }}
              />
            </Box>
            <div className="footer-menu-search">
              <TextField
                className="footer-textfield"
                placeholder="Email"
                onChange={handleEmailChange}
                InputProps={{
                  autoComplete: "off",
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={goToSignup}
                        edge="end"
                        className="footer-search-icon"
                      >
                        <CallMadeRoundedIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </div>
            <Box
              sx={{
                display: "flex",
                gap: { xs: 2, sm: 2, md: 3, lg: 3 },
              }}
            >
              {/* <a
                href="https://www.tiktok.com/"
                underline="none"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={music} alt="music" width={21} />
              </a>
              <a
                href="https://www.youtube.com/"
                underline="none"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={youtube} alt="music" width={21} />
              </a>
              <a
                href="https://www.facebook.com/"
                underline="none"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={facebook} alt="facebook" />
              </a>
              <a
                href="https://www.instagram.com/"
                underline="none"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={instagram} alt="facebook" width={21} />
              </a> */}
              <a
                href="https://twitter.com/BoldPreso"
                underline="none"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={twitter} alt="facebook" width={21} />
              </a>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </footer>
  );
}

export default Footer;
