import { Box, Divider, Grid, Typography, styled } from "@mui/material";
import { Card, CardContent } from "@mui/material";
import React from "react";
import './Feature.css';
// import avatarImg from '../../assets/cardAvatar.png'
import howWorks from '../../assets/work.jpg'
import keyfeature from '../../assets/keyfeature.jpg'
import transition from '../Common/Transition/Transition';
import { Link } from "react-router-dom";
import { ArrowOutward } from "@mui/icons-material";

// const testData = [
//   {
//     id: 1,
//     name: "John Doe",
//     photo: avatarImg,
//     comment:
//       "structured presentation with a photo, it saves me a lot of time! Great tool! Thank you Anurag!",
//   },
//   {
//     id: 2,
//     name: "Jane Smith",
//     photo: avatarImg,
//     comment:
//       "structured presentation with a photo, it saves me a lot of time! Great tool! Thank you Anurag!",
//   },
//   {
//     id: 3,
//     name: "Michael Johnson",
//     photo: avatarImg,
//     comment:
//       "structured presentation with a photo, it saves me a lot of time! Great tool! Thank you Anurag!",
//   },
//   {
//     id: 4,
//     name: "Smith",
//     photo: avatarImg,
//     comment:
//       "structured presentation with a photo, it saves me a lot of time! Great tool! Thank you Anurag!",
//   },
//   {
//     id: 5,
//     name: "Jane",
//     photo: avatarImg,
//     comment:
//       "structured presentation with a photo, it saves me a lot of time! Great tool! Thank you Anurag!",
//   },
//   {
//     id: 6,
//     name: "Jhon wick",
//     photo: avatarImg,
//     comment:
//       "structured presentation with a photo, it saves me a lot of time! Great tool! Thank you Anurag!",
//   },
// ];

function Feature() {

  const StyledLink = styled(Link)(({ theme }) => ({
    textDecoration: 'none',
    color: 'inherit',
    display: 'flex',
    alignItems: 'center',
  }));

  return (
    <Box
      className="feature-container"
      sx={{ marginTop: { xs: "100px", sm: "100px", md: "12%", lg: "12%" } }}
    >
      <Grid container>
        <Grid item xs={12} sm={5} md={6} lg={6}>
          <Box>
            <Typography
              variant="h5"
              className="feature-left-sub-text"
              sx={{
                fontSize: { xs: "20px", sm: "20px", md: "24px", lg: "24px" },
              }}
            >
              Your AI-Powered Presentation Creator
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} sm={2} md={1} lg={1}></Grid>
        <Grid item xs={12} sm={5} md={5} lg={5}>
          <Typography
            variant="h5"
            className="feature-right-sub-text"
            sx={{
              fontSize: { xs: "14px", sm: "16px", md: "16px", lg: "18px" },
              marginTop: { xs: "16px", sm: "16px", md: "0", lg: "0" },
            }}
          >
            Welcome to BoldPreso, the innovative app that brings your ideas to
            life in the form of stunning presentations, all with the power of
            AI. Say goodbye to the tedious process of manually creating slides
            and let BoldPreso's cutting-edge technology do the work for you.
          </Typography>
        </Grid>
      </Grid>
      <Divider
        component="div"
        sx={{ height: "1px", bgcolor: "rgba(255, 255, 255, 0.35)", my: 6 }}
      />
      <Grid container>
        <Grid item xs={12} sm={12} md={5} lg={5} mb={5}>
          <Typography
            variant="h6"
            className="feature-title"
            sx={{ fontWeight: "600 !important" }}
          >
            HOW BOLDPRESO WORKS
          </Typography>
          <Box sx={{ display: "flex", flexDirection: "column", gap: 2, mt: 4 }}>
            <Typography
              variant="h5"
              className="feature-right-sub-text"
              sx={{
                fontSize: { xs: "14px", sm: "16px", md: "16px", lg: "18px" },
              }}
            >
              <Typography variant="h6" sx={{ color: "#fff", mb: 1 }}>
                1. Input your ideas:
              </Typography>
              Simply enter your presentation topic, main points, or specific
              requirements for your slides into BoldPreso's intuitive interface.
            </Typography>
            <Typography
              variant="h5"
              className="feature-right-sub-text"
              sx={{
                fontSize: { xs: "14px", sm: "16px", md: "16px", lg: "18px" },
              }}
            >
              <Typography variant="h6" sx={{ color: "#fff", mb: 1 }}>
                2. AI-powered generation:
              </Typography>
              Our advanced AI algorithms analyze your input and generate a
              professional, visually appealing presentation tailored to your
              needs.
            </Typography>
            <Typography
              variant="h5"
              className="feature-right-sub-text"
              sx={{
                fontSize: { xs: "14px", sm: "16px", md: "16px", lg: "18px" },
              }}
            >
              <Typography variant="h6" sx={{ color: "#fff", mb: 1 }}>
                3. Customize and enhance:
              </Typography>
              Review the AI-generated slides and make any necessary adjustments
              or additions to personalize your presentation further.
            </Typography>
            <Typography
              variant="h5"
              className="feature-right-sub-text"
              sx={{
                fontSize: { xs: "14px", sm: "16px", md: "16px", lg: "18px" },
              }}
            >
              <Typography variant="h6" sx={{ color: "#fff", mb: 1 }}>
                4. Export and share:
              </Typography>
              Export your presentation in popular file formats like PPTX or PDF,
              ready to be shared with your audience or collaborators.
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={1} lg={1}></Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} mb={5}>
          <Box
            component="img"
            src={howWorks}
            alt="howWorks"
            sx={{ width: "100%", height: "100%", borderRadius: "25px" }}
          />
        </Grid>
      </Grid>
      <Divider
        component="div"
        sx={{ height: "1px", bgcolor: "rgba(255, 255, 255, 0.35)", my: 6 }}
      />
      <Grid container>
        <Grid item xs={12} sm={12} md={6} lg={6} mb={5} className="d-flex">
          <Box
            component="img"
            src={keyfeature}
            alt="keyfeature"
            sx={{ width: "100%", height: "80%", borderRadius: "25px" }}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={1} lg={1}></Grid>
        <Grid item xs={12} sm={12} md={5} lg={5} mb={5}>
          <Typography
            variant="h6"
            className="feature-title"
            sx={{ fontWeight: "600 !important" }}
          >
            WHY CHOOSE BOLDPRESO ?
          </Typography>
          <Box sx={{ display: "flex", flexDirection: "column", gap: 2, mt: 4 }}>
            <Typography
              variant="h5"
              className="feature-right-sub-text"
              sx={{
                fontSize: { xs: "14px", sm: "16px", md: "16px", lg: "18px" },
              }}
            >
              <Typography variant="h6" sx={{ color: "#fff", mb: 1 }}>
                1. Unmatched efficiency:
              </Typography>
              Create presentations faster than ever before without sacrificing
              quality.
            </Typography>
            <Typography
              variant="h5"
              className="feature-right-sub-text"
              sx={{
                fontSize: { xs: "14px", sm: "16px", md: "16px", lg: "18px" },
              }}
            >
              <Typography variant="h6" sx={{ color: "#fff", mb: 1 }}>
                2. Brand consistency:
              </Typography>
              Maintain a consistent brand image across all your presentations
              with BoldPreso's AI-powered design engine.
            </Typography>
            <Typography
              variant="h5"
              className="feature-right-sub-text"
              sx={{
                fontSize: { xs: "14px", sm: "16px", md: "16px", lg: "18px" },
              }}
            >
              <Typography variant="h6" sx={{ color: "#fff", mb: 1 }}>
                3. Flexibility and customization:
              </Typography>
              Tailor your presentations to match your brand guidelines or
              personal preferences with ease.
            </Typography>
            <Typography
              variant="h5"
              className="feature-right-sub-text"
              sx={{
                fontSize: { xs: "14px", sm: "16px", md: "16px", lg: "18px" },
              }}
            >
              <Typography variant="h6" sx={{ color: "#fff", mb: 1 }}>
                4. Cutting-edge AI technology:
              </Typography>
              Benefit from our state-of-the-art AI technology, backed by years
              of research and development.
            </Typography>
            <Typography
              variant="h5"
              className="feature-right-sub-text"
              sx={{
                fontSize: { xs: "14px", sm: "16px", md: "16px", lg: "18px" },
              }}
            >
              <Typography variant="h6" sx={{ color: "#fff", mb: 1 }}>
                4. Dedicated support:
              </Typography>
              Get prompt assistance from our friendly and knowledgeable customer
              support team whenever you need it.
            </Typography>
          </Box>
        </Grid>
      </Grid>
      <Divider
        component="div"
        sx={{ height: "1px", bgcolor: "rgba(255, 255, 255, 0.35)", my: 6 }}
      />
      <Grid container spacing={2} mt={4} mb={4}>
        <Grid item xs={12} sm={12} md={12} lg={12} className="d-flex">
          <Card
            className="keyfeature-card"
            sx={{
              width: { md: "70%", lg: "50%" },
              p: { xs: "10px", sm: "20px", md: "30px", lg: "30px" },
            }}
          >
            <Box sx={{width:'100%',textAlign:'center'}}>
              <Typography
                variant="h6"
                className="key-feature-title"
                sx={{ fontWeight: "600 !important", p: '20px' }}
              >
                KEY FEATURES
              </Typography>
            </Box>
            <CardContent>
              <Box
                sx={{ display: "flex", flexDirection: "column", gap: 2, mt: 2 }}
              >
                <Typography
                  variant="h5"
                  className="feature-right-sub-text"
                  sx={{
                    fontSize: {
                      xs: "14px",
                      sm: "16px",
                      md: "16px",
                      lg: "16px",
                    },
                  }}
                >
                  <Typography variant="h6" sx={{ color: "#fff", mb: 1 }}>
                    1. Smart slide creation:
                  </Typography>
                  BoldPreso's intelligent AI technology understands your input
                  and creates slides with relevant content, images, and layouts.
                </Typography>
                <Typography
                  variant="h5"
                  className="feature-right-sub-text"
                  sx={{
                    fontSize: {
                      xs: "14px",
                      sm: "16px",
                      md: "16px",
                      lg: "16px",
                    },
                  }}
                >
                  <Typography variant="h6" sx={{ color: "#fff", mb: 1 }}>
                    2. Time-saving efficiency:
                  </Typography>
                  Reduce your presentation creation time from hours to minutes,
                  allowing you to focus on delivering your message with impact.
                </Typography>
                <Typography
                  variant="h5"
                  className="feature-right-sub-text"
                  sx={{
                    fontSize: {
                      xs: "14px",
                      sm: "16px",
                      md: "16px",
                      lg: "16px",
                    },
                  }}
                >
                  <Typography variant="h6" sx={{ color: "#fff", mb: 1 }}>
                    3. Professionally designed templates:
                  </Typography>
                  Choose from a wide selection of professionally designed
                  templates to give your presentations a polished and cohesive
                  look.
                </Typography>
                <Typography
                  variant="h5"
                  className="feature-right-sub-text"
                  sx={{
                    fontSize: {
                      xs: "14px",
                      sm: "16px",
                      md: "16px",
                      lg: "16px",
                    },
                  }}
                >
                  <Typography variant="h6" sx={{ color: "#fff", mb: 1 }}>
                    4. Real-time collaboration:
                  </Typography>
                  Share your presentations with team members and collaborate in
                  real-time to refine and enhance your slides.
                </Typography>
                <Typography
                  variant="h5"
                  className="feature-right-sub-text"
                  sx={{
                    fontSize: {
                      xs: "14px",
                      sm: "16px",
                      md: "16px",
                      lg: "16px",
                    },
                  }}
                >
                  <Typography variant="h6" sx={{ color: "#fff", mb: 1 }}>
                    5. Secure cloud storage:
                  </Typography>
                  Store your presentations securely in the cloud, accessible
                  from anywhere and on any device.
                </Typography>
              </Box>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      {/* <Box>
        <Typography variant="h6" className="feature-middle-title">
          Testimonials from satisfied users
        </Typography>
      </Box>
      <Grid container spacing={2} mt={4} mb={4}>
        {testData.map((user) => (
          <Grid item xs={12} sm={6} md={4} lg={4} xl={3} key={user.id}>
            <Card className="feature-card">
              <CardHeader
                avatar={<Avatar src={user.photo} />}
                title={user.name}
                className="feature-card-title"
              />
              <CardContent>
                <Typography
                  component="p"
                  className="feature-card-content"
                  sx={{
                    fontSize: {
                      xs: "14px",
                      sm: "16px",
                      md: "16px",
                      lg: "18px",
                    },
                  }}
                >
                  {user.comment}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid> */}
      <Box mt={2} sx={{ maxWidth: '800px', margin: 'auto' }}>
        <Typography
          className="section6-content-center-text"
          sx={{
            fontSize: { xs: "16px", sm: "16px", md: "18px", lg: "20px" },
          }}
        >
          Take the first step towards presentation greatness. Sign up for
          BoldPreso today and experience the difference AI-powered slide
          creation can make. Your audience won't know what hit them – in the
          best way possible.
        </Typography>
      </Box>
      <Box className="d-flex" sx={{ height: "150px", mb: 4 }}>
        <StyledLink
          to={"/signup"}
          className="section6-create-btn"
          sx={{
            width: { xs: "300px", sm: "330px", md: "350px", lg: "380px" },
          }}
        >
          <Box
            className="section6-create-btn-text"
            sx={{
              width: "75%",
              borderWidth: { xs: "2px", sm: "2px", md: "2px", lg: "1px" },
            }}
          >
            <p>create Presentation now</p>
          </Box>
          <Box
            className="section6-create-btn-icon"
            sx={{
              borderWidth: { xs: "2px", sm: "2px", md: "2px", lg: "1px" },
            }}
          >
            <ArrowOutward />
          </Box>
        </StyledLink>
      </Box>
    </Box>
  );
}

export default transition(Feature);
