import React from 'react';
import './style.css'
import BoldPressoLogo from '../../assets/boldpreso_logo.png';
import { Box, Button, Typography } from '@mui/material';
import { NavLink } from "react-router-dom";

const PageNotFound = () => {
    return (
        <Box className="Notpage-container">
            <Box>
                <img src={BoldPressoLogo} alt="BoldPressoLogo" width={200} height={75} />
            </Box>
            <Typography variant='h2' className='Notpage-title'
                sx={{
                    fontSize: { xs: '2rem', sm: '2rem', md: '4rem', lg: '4rem' }
                }}
            >404 - Page Not Found</Typography>
            <Typography variant='p' className='Notpage-text'>Page you are trying to open doesn't exist.<br /> You may have mistyped the address, or the page has been moved to another URL.</Typography>
            <Typography variant='p' className='Notpage-text'>Don't worry, you can go back to the homepage:</Typography>
            <Box sx={{ margin: "30px 0" }}>
                <NavLink to="/">  <Button variant='contained' className='Notpage-btn'>Go back</Button> </NavLink>
            </Box>
        </Box>
    )
}

export default PageNotFound