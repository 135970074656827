import React, { useState } from "react";
import "./Navbar.css";
import Drawer from "./Drawer";

import { Box, Button, Stack } from "@mui/material";
import { NavLink, Link } from "react-router-dom";

import boldpreso_logo from "../../assets/boldpreso_logo.png";

function Navbar() {
  const [navScroll, setnavScroll] = useState(false);

  const changeColor = () => {
    if (window.scrollY >= 90) {
      setnavScroll(true);
    } else {
      setnavScroll(false);
    }
  };
  window.addEventListener("scroll", changeColor);

  return (
    <>
      <Box
        className={`nav-container ${
          navScroll === true ? "active" : "inactive"
        }`}
      >
        <Stack direction={"row"} justifyContent={"space-between"}>
          <NavLink to="/">
            {" "}
            <img src={boldpreso_logo} alt="logo" width={175} height={55} />{" "}
          </NavLink>
          <Stack direction={"row"} className="nav-menu">
            <Stack
              direction="row"
              spacing={3}
              pr={3}
              justifyContent={"center"}
              alignItems={"center"}
              sx={{
                display: {
                  md: "flex",
                  lg: "flex",
                  xl: "flex",
                  sm: "none",
                  xs: "none",
                },
              }}
            >
              <NavLink
                to={"/"}
                className={({ isActive }) =>
                  isActive ? "menu-active" : "menu-inactive"
                }
              >
                Home
              </NavLink>
              <NavLink
                to={"/feature"}
                className={({ isActive }) =>
                  isActive ? "menu-active" : "menu-inactive"
                }
              >
                Features
              </NavLink>
              <NavLink
                to={"/price"}
                className={({ isActive }) =>
                  isActive ? "menu-active" : "menu-inactive"
                }
              >
                Pricing
              </NavLink>
              {/* <NavLink
                to={"/about"}
                className={({ isActive }) =>
                  isActive ? "menu-active" : "menu-inactive"
                }
              >
                About Us
              </NavLink> */}
              <Link to="/login">
                <Button className="nav-login-btn">Login</Button>
              </Link>
              <Link to="/signup">
                <Button className="nav-signup-btn">Get Started</Button>
              </Link>
            </Stack>
          </Stack>
          <Stack
            justifyContent={"center"}
            sx={{
              display: {
                md: "none",
                lg: "none",
                xl: "none",
                sm: "flex",
                xs: "flex",
              },
            }}
          >
            <Drawer />
          </Stack>
        </Stack>
      </Box>
    </>
  );
}

export default Navbar;
