import { Box, Typography, Grid, IconButton } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import DownloadIcon from "@mui/icons-material/Download";
import "./MyFile.css";

export default function KnowledgeBase({
  handleOpen,
  filesData,
  hadleFileDelete,
  handleDownloadFile,
}) {
  return (
    <Box
      sx={{
        width: { xs: "340px", sm: "500px", md: "500px", lg: "500px" },
        minHeight: "600px",
      }}
    >
      <Grid container>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Box className="file-headerBox">
            <Typography className="file-TypoText" variant="h6">
              Knowledge base
            </Typography>
            <Typography
              onClick={handleOpen}
              className="file-addFile"
              variant="h6"
            >
              Add file
            </Typography>
          </Box>
        </Grid>

        <Grid item xs={12} sm={12} md={12} lg={12}>
          {filesData.map((file) => (
            <Box mt={1} key={file.id} className="file-FileList">
              <Typography pl={1} className="list-filetext">
                {file.name} {file.extension}
              </Typography>
              <Box className="file-boxContent">
                <IconButton
                  onClick={() => hadleFileDelete(file.name)}
                  disableRipple
                >
                  <DeleteIcon className="del-icn" />
                </IconButton>
                <IconButton
                  disableRipple
                  onClick={() => handleDownloadFile(file)}
                >
                  <DownloadIcon className="down-icn" />
                </IconButton>
              </Box>
            </Box>
          ))}
        </Grid>
      </Grid>
    </Box>
  );
}
