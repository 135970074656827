import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import * as API from "../../api/index";

const initialState = {
  ui: {
    url: {
        loading: false,
        status: ""
    },
    file: {
      status: "",
      loading: false,
    },
    download: {
      status: "",
      loading: false,
    },
  },
};

const sendUrlData = createAsyncThunk(
  "post/sendUrlData",
  async (params, { rejectWithValue }) => {
    try {
      const res = await API.sendUrlData(params);
      return res.data;
    } catch (err) {
      return rejectWithValue(err.response ? err.response.data : err.message);
    }
  }
);

const sendFileData = createAsyncThunk(
  "post/sendFileData",
  async (params, { rejectWithValue }) => {
    try {
      const res = await API.sendFileData(params);
      return res.data;
    } catch (err) {
      return rejectWithValue(err.response ? err.response.data : err.message);
    }
  }
);

const deleteFile = createAsyncThunk("delete/file", async (params, { rejectWithValue }) => {
    try{
      const res = await API.deleteFile(params);
    return res.data;
    }
    catch (err) {
      return rejectWithValue(err.response ? err.response.data : err.message);
    }
});

const downloadFile = createAsyncThunk(
  "download/file",
  async (params, { rejectWithValue }) => {
    const { name, extension } = params;
    try {
      const res = await API.downloadFile(params);
      const blob = new Blob([res.data], { type: res.headers['content-type'] });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = name ? `${name}${extension}` : `file${extension}`;
      document.body.appendChild(a);
      a.click();
      a.remove();
      window.URL.revokeObjectURL(url);
      return { message: 'File downloaded successfully' };
    } catch (err) {
      return rejectWithValue(err.response ? err.response.data : err.message);
    }
  }
);

export const myFileSlice = createSlice({
  name: "myFile",
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    builder
      .addCase(sendUrlData.pending, (state, action) => {
        state.ui.url.loading = true;
      })
      .addCase(sendUrlData.fulfilled, (state, action) => {
        state.ui.url.loading = false;
        state.ui.url.status = action.payload.message;
      })
      .addCase(sendUrlData.rejected, (state, action) => {
          state.ui.url.loading = false;
          state.ui.url.status = action.payload.detail || action.error.message;
      });
    builder
      .addCase(sendFileData.pending, (state, action) => {
        state.ui.file.loading = true;
      })
      .addCase(sendFileData.fulfilled, (state, action) => {
        state.ui.file.loading = false;
        state.ui.file.status = action.payload.message;
      })
      .addCase(sendFileData.rejected, (state, action) => {
          state.ui.file.loading = false;
          state.ui.file.status = action.payload.detail || action.error.message;
      }); 
      builder
      .addCase(downloadFile.pending, (state) => {
        state.ui.download.loading = true;
      })
      .addCase(downloadFile.fulfilled, (state, action) => {
        state.ui.download.loading = false;
        state.ui.download.status = action.payload.message;
      })
      .addCase(downloadFile.rejected, (state, action) => {
        state.ui.download.loading = false;
        state.ui.download.status = action.payload.detail || action.error.message;
      });  
  },
});

export { sendUrlData, sendFileData ,deleteFile,downloadFile };
export const selectFileLoading = (state) => state.myFile.ui.file.loading;
export const selectUrlLoading = (state) => state.myFile.ui.url.loading;

export default myFileSlice.reducer;
