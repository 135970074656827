import React from 'react';
import "./Home.css";

import { Box, Typography } from '@mui/material'
// import presentationImg1 from "../../assets/presentationImg1.png";
// import presentationImg2 from "../../assets/presentationImg2.png";
import AI_img1 from "../../assets/ppt_images/AI_img1.jpg";
import AI_img2 from "../../assets/ppt_images/AI_img2.jpg";
import Crypto_img1 from "../../assets/ppt_images/Crypto_img1.jpg";
import Crypto_img2 from "../../assets/ppt_images/Crypto_img2.jpg";
import Robot_img1 from "../../assets/ppt_images/Robot_img1.jpg";
import Robot_img2 from "../../assets/ppt_images/Robot_img2.jpg";
import Nano_img1 from "../../assets/ppt_images/Nano_img1.jpg";
import Nano_img2 from "../../assets/ppt_images/Nano_img2.jpg";
import reactangle from '../../assets/Rectangle.png';
import PresentationRectangle from '../../assets/PresentationRectangle.png';

const boxWidth = {
    xs: "250px",
    sm: "250px",
    md: "300px",
    lg: "400px"
  };


function MiddleContent() {
    return (
      <Box
        className="section4-presentation-container"
        sx={{ minHeight: { xs: "500px", sm: "500px", md: "500px", lg: "600px" } }}
      >
        <Box
          sx={{
            background: `url(${reactangle})`,
            minWidth: { xs: "100%", sm: "100%", md: "550px", lg: "700px" },
          }}
        >
          <Typography
            className="section4-main-card-title"
            sx={{
              fontSize: { xs: "16px", sm: "16px", md: "24px", lg: "32px" },
            }}
          >
            DIVE INTO THE WORLD OF HASSLE - <br />
            FREE , IMPACTFUL PRESENTATIONS TODAY!
          </Typography>
          <Typography
            className="section4-main-card-text"
            sx={{
              fontSize: { xs: "16px", sm: "16px", md: "18px", lg: "18px" },
            }}
          >
            Whether you're pitching to investors, teaching a class, or sharing
            insights at a conference, BoldPreso ensures your message is not just
            heard, but also remembered
          </Typography>
          <Typography
            className="section4-main-card-text"
            sx={{
              fontSize: { xs: "16px", sm: "16px", md: "18px", lg: "18px" },
            }}
          >
            When you create with BoldPreso, you're not just making slides –
            you're making an impact. Our app empowers you to communicate your
            ideas with clarity, confidence, and style. Stand out from the crowd
            and achieve the results you desire with presentations that are as
            bold as your vision.
          </Typography>
        </Box>
        <Box
          className="section4-presentation-box"
          sx={{
            background: `url(${PresentationRectangle})`,
          }}
        >
            <Box
              component="img"
              src={AI_img1}
              alt="AI_img1"
              className="presentation-img"
              sx={{width:boxWidth}}
            ></Box>
            <Box
              component="img"
              src={AI_img2}
              alt="AI_img2"
              className="presentation-img"
              sx={{width:boxWidth}}
            ></Box>
          <Typography
            mt={3}
            sx={{
              fontSize: { xs: "18px", sm: "25px", md: "15px", lg: "20px" },
            }}
          >
            ARTIFICIAL INTELLIGENCE
          </Typography>
          </Box>
        <Box
          className="section4-presentation-box"
          sx={{
            background: `url(${PresentationRectangle})`,
          }}
        >
            <Box
              component="img"
              src={Robot_img1}
              alt="Robot_img1"
              className="presentation-img"
              sx={{width:boxWidth}}
            ></Box>
            <Box
              component="img"
              src={Robot_img2}
              alt="Robot_img2"
              className="presentation-img"
              sx={{width:boxWidth}}
            ></Box>
          <Typography
            mt={3}
            sx={{
              fontSize: { xs: "18px", sm: "25px", md: "15px", lg: "20px" },
            }}
          >
            ROBOTICS
          </Typography>
          </Box>
        <Box
          className="section4-presentation-box"
          sx={{
            background: `url(${PresentationRectangle})`,
          }}
        >
            <Box
              component="img"
              src={Crypto_img1}
              alt="Crypto_img1"
              className="presentation-img"
              sx={{width:boxWidth}}
            ></Box>
            <Box
              component="img"
              src={Crypto_img2}
              alt="Crypto_img2"
              className="presentation-img"
              sx={{width:boxWidth}}
            ></Box>
          <Typography
            mt={3}
            sx={{
              fontSize: { xs: "18px", sm: "25px", md: "15px", lg: "20px" },
            }}
          >
            CRYPTOCURRENCY
          </Typography>
        </Box>
        <Box
          className="section4-presentation-box"
          sx={{
            background: `url(${PresentationRectangle})`,
          }}
        >
            <Box
              component="img"
              src={Nano_img1}
              alt="Nano_img1"
              className="presentation-img"
              sx={{width:boxWidth}}
            ></Box>
            <Box
              component="img"
              src={Nano_img2}
              alt="Nano_img2"
              className="presentation-img"
              sx={{width:boxWidth}}
            ></Box>
          <Typography
            mt={3}
            sx={{
              fontSize: { xs: "18px", sm: "25px", md: "15px", lg: "20px" },
            }}
          >
            NANO TECHNOLOGY
          </Typography>
        </Box>
      </Box>
    );
}

export default MiddleContent